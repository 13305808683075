import React, { useState } from "react";
import { IUserSettingsViewModel, IUserSettings } from "../../../js/viewmodels/UserSettingsViewModel";

export default function SettingsModal(args: ISettingsModal) {
  const { viewModel } = args;

  const [localSettings, setLocalSettings] = useState<IUserSettings>({ ...viewModel.userSettings });
  if (!viewModel.user) {
    return <></>;
  }

  // Local state copy of userSettings
  // const [localSettings, setLocalSettings] = useState<IUserSettings>({ ...viewModel.userSettings });

  // Handle checkbox changes
  const handleCheckboxChange =
    (name: keyof IUserSettings) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setLocalSettings((prevSettings) => ({
        ...prevSettings,
        [name]: e.target.checked,
      }));
    };

  // Handle select changes
  const handleSelectChange =
    (name: keyof IUserSettings) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      setLocalSettings((prevSettings) => ({
        ...prevSettings,
        [name]: e.target.value,
      }));
    };

  // Cancel function
  const cancel = () => {
    viewModel.hide();
  };

  // Submit function to apply changes
  const submit = () => {
    viewModel.updateUserSettings(localSettings);
    viewModel.submit();
  };

  return (
    <div id="settingsModal" className="modal show" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center">
              Settings for {viewModel.user.u_name}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={cancel}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {[
              {
                name: "defaultLogEditFormat",
                label: "Default Log Editing Time Format",
                value: localSettings.defaultLogEditFormat,
                options: ["hh 'hours and' mm 'minutes'", "hh:mm:ss", "h:mm:ss", "hh:mm", "h:mm", "mm"],
              },
              {
                name: "darkMode",
                label: "Dark Mode",
                value: localSettings.darkMode,
              },
              {
                name: "showEmojis",
                style: { display: "none" },
                label: "Show Emojis",
                value: localSettings.showEmojis,
              },
              {
                name: "titleChange",
                label: "Cross Site Title Change",
                value: localSettings.titleChange,
              },
              {
                name: "showActiveLogGlow",
                label: "Enable Active Log Glow",
                value: localSettings.showActiveLogGlow,
              },
            ].map((setting) => (
              <div
                className="form-check mb-3"
                style={setting.style || undefined}
                key={setting.name}
              >
                {setting.options ? (
                  <div>
                    <label htmlFor={setting.name} className="form-label">
                      {setting.label}
                    </label>
                    <select
                      id={setting.name}
                      className="form-select"
                      value={setting.value}
                      onChange={handleSelectChange(setting.name as keyof IUserSettings)}
                    >
                      {setting.options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={setting.name}
                      checked={setting.value as boolean}
                      onChange={handleCheckboxChange(setting.name as keyof IUserSettings)}
                    />
                    <label className="form-check-label" htmlFor={setting.name}>
                      {setting.label}
                    </label>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={cancel}
            >
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={submit}>
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ISettingsModal {
  viewModel: IUserSettingsViewModel;
}
