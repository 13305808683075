import React from 'react';
import { ICustomerMaintenanceViewModel } from '../../js/viewmodels/CustomerMaintenanceSection';
import ErrorDiv from "../common/ErrorModal";
import LoadingScreen from "../common/loading.tsx";
import  CustomerDeleteModal  from "../CustForms/Modals/DeleteCustomer.tsx";

import CustomerTable from "./components/CustomerRow.tsx";
import CustomerDetailsTabs from "./components/CustomerDetailsTabs.tsx";
import CustomerActionButtons from "./components/CustomerActionButtons.tsx";
import CustomerSearchBar from "./components/CustomerSearchBar.tsx";


const CustomerMaintenanceScreen: React.FC<ICustomerMaintenanceScreen> = ({ model }) => {
  return (
    <>
<div className="container ">
  <LoadingScreen loading={model.showLoading} />
  <h1 className="mb-4 text-left">Customer Maintenance</h1>
  <ErrorDiv
    success={model.actionResponse}
    errorMessage={model.responseMessage}
    isVisible={model.showErrorDiv}
    onClose={() => model.setShowResponseDiv(false)}
  />
  <div className="card">
    <div className="card-body">
      <CustomerSearchBar
        customerData={model.customerData}
        handleCustomerSelection={model.handleCustomerSelection}
        handleSearch={model.handleSearch}
        customer={model.customer}
        setCustomer={model.setCustomer}
        handleRefresh={model.handleRefresh}
        setShowTable={model.setShowTable}
        setActiveCustomerDetails={model.setActiveCustomerDetails}
      />
      {!model.customer && (
        <CustomerActionButtons
          GroupCustomerSelected={model.selectedCustomers}
          CustomerCreationCallback={model.handleRefresh}
          CustomerGroupCreationCallback={model.handleRefresh}
          CustomerGroupDeletionCallback={model.handleRefresh}
          groups={model.groups}
        />
      )}
      {model.showTable && (
        <CustomerTable
          filteredData={model.filteredData}
          handleCustomerSelection={model.handleCustomerSelection}
          handleCustomerGroupSelection={model.handleCustomerGroupSelection}
        />
      )}
      {model.customer && (
        <div>
          <div className="pb-4 mb-4">
            {/* <CustomerSettingsModal
              callback={callback}
            /> */}
            <CustomerDeleteModal
              callback={model.handleRefresh}
              custcode={model.activeCustomerDetails.C_DIALCODE}
            />
          </div>
          <div className="mb-4">
            <CustomerDetailsTabs
              activeTabKey={model.activeTabKey}
              setActiveTabKey={model.setActiveTabKey}
              activeCustomerDetails={model.activeCustomerDetails}
              customerData={model.customerData}
            />
          </div>
        </div>
      )}
    </div>
  </div>
</div>
</>

    );
};

interface ICustomerMaintenanceScreen {
  model: ICustomerMaintenanceViewModel;
}

export default CustomerMaintenanceScreen;
