import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { FormCheck } from 'react-bootstrap';
import customerMaintenance from '../../../js/classes/CustomerMaintence.ts'; // Adjust the import path according to your project structure

interface CustomerTableProps {
    filteredData: customerMaintenance[];
    handleCustomerSelection: (dialCode: string) => void;
    handleCustomerGroupSelection: (selectedCustomers: customerMaintenance[]) => void;
}

const CustomerTable: React.FC<CustomerTableProps> = ({ filteredData, handleCustomerSelection, handleCustomerGroupSelection }) => {
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);
    const [sortConfig, setSortConfig] = useState<{ key: keyof customerMaintenance; direction: 'ascending' | 'descending' }>({ key: 'C_DIALCODE', direction: 'ascending' });
    const [selectedCustomers, setSelectedCustomers] = useState<customerMaintenance[]>([]);
    const selectedCustomersRef = useRef<customerMaintenance[]>([]);

    const sortedData = useMemo(() => {
        const sortableItems = [...filteredData];
        sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        return sortableItems;
    }, [filteredData, sortConfig]);

    const requestSort = (key: keyof customerMaintenance) => {
        setSortConfig((prevSortConfig) => ({
            key,
            direction: prevSortConfig.key === key && prevSortConfig.direction === 'ascending' ? 'descending' : 'ascending',
        }));
    };

    const handleCheckboxChange = (customer: customerMaintenance) => {
        setSelectedCustomers((prevState) => {
            const isSelected = prevState.some((selected) => selected.C_DIALCODE === customer.C_DIALCODE);
            const newSelectedCustomers = isSelected
                ? prevState.filter((selected) => selected.C_DIALCODE !== customer.C_DIALCODE)
                : [...prevState, customer];

            selectedCustomersRef.current = newSelectedCustomers;
            return newSelectedCustomers;
        });
    };

    useEffect(() => {
        handleCustomerGroupSelection(selectedCustomersRef.current);
    }, [selectedCustomers, handleCustomerGroupSelection]);

    const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement>, dialCode: string) => {
        if ((event.target as HTMLInputElement).type === 'checkbox') {
            return;
        }
        handleCustomerSelection(dialCode);
    };

    const selectAll = () => {
        const newSelectedCustomers = sortedData.length === selectedCustomers.length ? [] : [...sortedData];
        setSelectedCustomers(newSelectedCustomers);
        selectedCustomersRef.current = newSelectedCustomers;
    };

    const isSelected = useCallback(
        (dialCode: string) => selectedCustomers.some((customer) => customer.C_DIALCODE === dialCode),
        [selectedCustomers]
    );

    const hoverStyle = (index: number) => ({
        color: parseInt(sortedData[index].usedTotal) < 0 ? 'red' : 'green',
    });

    return (
        <table className="table mt-4">
            <thead>
                <tr>
                    <th>
                        <FormCheck
                            onChange={selectAll}
                            checked={sortedData.length > 0 && sortedData.length === selectedCustomers.length}
                        />
                    </th>
                    <th onClick={() => requestSort('C_DIALCODE')}>Dial Code</th>
                    <th onClick={() => requestSort('C_NAME')}>Name</th>
                    <th onClick={() => requestSort('usedTotal')}>Contract Hours Left</th>
                    <th onClick={() => requestSort('customerGroups')}>Customer Group</th>
                </tr>
            </thead>
            <tbody>
                {sortedData.map((customer, index) => (
                    <tr
                        key={customer.C_DIALCODE}
                        onClick={(event) => handleRowClick(event, customer.C_DIALCODE)}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(null)}
                    >
                        <td>
                            <FormCheck
                                checked={isSelected(customer.C_DIALCODE)}
                                onChange={() => handleCheckboxChange(customer)}
                            />
                        </td>
                        <td>{customer.C_DIALCODE}</td>
                        <td>{customer.C_NAME}</td>
                        <td
                            style={hoverStyle(index)}
                            title={`${Math.abs(parseInt(customer.usedTotal))} minute(s)`}
                        >
                            {hoverIndex === index
                                ? `${Math.abs(parseInt(customer.usedTotal))} minute(s)`
                                : `${Math.floor(parseInt(customer.usedTotal) / 60)}h`}
                        </td>
                        <td>{customer.customerGroups}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default CustomerTable;
