import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { BasicInfoForm, BillingDetailsForm, SiteDetailsForm } from "../../CustForms/CustomerForms";
import { CommentsForm } from "../../CustForms/Comments/CommentsForm";

interface CustomerDetailsTabsProps {
  activeTabKey: string;
  setActiveTabKey: (key: string) => void;
  activeCustomerDetails: Partial<any>;
  customerData: any[];
}

const CustomerDetailsTabs: React.FC<CustomerDetailsTabsProps> = ({
  activeTabKey,
  setActiveTabKey,
  activeCustomerDetails,
  customerData,
}) => {
  return (
    <Tabs
      id="customer-details-tabs"
      activeKey={activeTabKey}
      onSelect={(k) => setActiveTabKey(k || "basicInfo")}
      className="mb-4"
    >
      <Tab eventKey="basicInfo" title="Basic Info">
        <BasicInfoForm customerDetails={activeCustomerDetails} />
      </Tab>
      <Tab eventKey="siteDetails" title="Site Details">
        <SiteDetailsForm customerDetails={activeCustomerDetails} />
      </Tab>
      <Tab eventKey="billing" title="Billing">
        <BillingDetailsForm
          customerDetails={activeCustomerDetails}
          customerOptions={customerData.map(
            (customer) => customer.C_DIALCODE
          )}
        />
      </Tab>
      <Tab eventKey="comments" title="Settings">
        <CommentsForm customerDetails={activeCustomerDetails} />
      </Tab>
    </Tabs>
  );
};

export default CustomerDetailsTabs;
