import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

interface LoadingProps {
  loading: boolean;
  children?: React.ReactNode;
}

const Loading: React.FC<LoadingProps> = ({ loading, children }) => {
  if (loading) {

    
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default Loading;
