import React from "react";
import LoadingButton from "./LoadingButton.tsx";

/**
 * Component for a button which shows a loading image when clicked.
 * See LoadingButton.jsx for details on that.
 * 
 * This component should be used for form submissions, or other requests which then wait on
 * a response of some kind.
 * eg. When downloading data from a server, or processing data in the background.
 * 
 * @param style Bootstrap button style, without the btn- prefix. eg. "primary" or "success"
 * @param text Text to display inside of the button
 * @param onClick Function to run when the user clicks on this button
 * @param requestInProgress View model attribute for keeping track of whether the AJAX (or other) request is running or not.
 * @param loadingText Text to display in the loading button while the request is in progress
 */
export default function SubmitButton({
    style="success",
    text="Submit",
    onClick=() => {},
    requestInProgress=false,
    loadingText="Loading..."
}: ISubmitButton){

    const classes = "btn btn-"+style;

    return requestInProgress ?
        (<LoadingButton style={style} text={loadingText}/>) :
        (<button className={classes} type="button" onClick={onClick}>{text}</button>)
    
}

interface ISubmitButton{
    style?: string;
    text?: string;
    onClick?: () => void;
    requestInProgress?: boolean;
    loadingText?: string;
}