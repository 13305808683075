/**
 * Class for facilitating ajax requests
 */
export default class AjaxUtil{

    /**
     * Performs a HTTP POST request, optionally with an inProgress flag,
     * and calls a callback function when done.
     * 
     * @param url URL to send the request to
     * @param args POST arguments to send in JSON format
     * @param success Callback to run after a successful request
     * @param error Callback to run after a failed request
     * @param setRequestInProgress Callback to run before and after the request
     */
    static fetchPost({
        url,
        args,
        success,
        error,
        setRequestInProgress
    }: IAjaxUtil) {

        if (typeof args === 'undefined'){
            args = {};
        }
        if (typeof error === 'undefined'){
            error = console.log;
        }
        if (typeof setRequestInProgress === 'undefined'){
            setRequestInProgress = () => {};
        }
        
        setRequestInProgress(true);

        fetch(url, {
            method: 'post',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(args)
        })
        .then(res => res.json())
        .then(
            (result: JSON) => {
                success(result);
                setRequestInProgress(false);
            },
            (errorMsg: string) => {
                error(errorMsg);
                setRequestInProgress(false);
            }
        );

    }

}

interface IAjaxUtil{
    url: string;
    args?: Object;
    success: (json: JSON) => void;
    error?: (msg: string) => void;
    setRequestInProgress?: (inProgress: boolean) => void;
}