import React, { useState } from "react";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import ErrorDiv from "../../common/ErrorModal";
import { Button, Form } from "react-bootstrap"; // Keeping these imports for Bootstrap classes
import IconButton from "../../IconButton.tsx"
import { PersonAdd   } from 'react-bootstrap-icons';
interface CustomerCreationModalProps {
  callback: (isSuccess: boolean) => void;
}

const CustomerCreationModal: React.FC<CustomerCreationModalProps> = ({ callback }) => {
  const [show, setShow] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [custName, setCustName] = useState("");

  const handleClose = (isSuccess: boolean) => {
    setShow(false);
    callback(isSuccess);
  };

  const handleShow = () => setShow(true);

  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
    setIsSuccess(!message);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!dialCode) {
      displayError("Please enter dial code");
      return;
    }
    if (!custName) {
      displayError("Please enter customer name");
      return;
    }

    try {
      const request = { C_NAME: custName };
      const url = `/api/createcustomer/${dialCode}`;
      const result = await HttpClientUtil.putRequest(url, request, true);

      if (!result.success) {
        displayError(result.message);
      } else {
        displayError("");
        handleClose(true);
      }
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };

  return (
    <>
      <IconButton
                btnStyle="btn btn-info mx-1"
                text="Create Customer"
                onClick={handleShow}
                icon={<PersonAdd   />}
                show={true}
                />
      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Customer</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>
              <div className="modal-body">
                <ErrorDiv
                  success={isSuccess}
                  errorMessage={responseMessage}
                  isVisible={showErrorDiv}
                  onClose={() => setShowResponseDiv(false)}
                />
                <Form.Group controlId="dialCode">
                  <Form.Label>Dial Code:</Form.Label>
                  <Form.Control
                    type="text"
                    value={dialCode}
                    onChange={(e) => setDialCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="custName">
                  <Form.Label>Customer Name:</Form.Label>
                  <Form.Control
                    type="text"
                    value={custName}
                    onChange={(e) => setCustName(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="modal-footer">
                <Button variant="secondary" onClick={() => handleClose(false)}>
                  Close
                </Button>
                <Button type="submit" variant="primary" onClick={handleSubmit}>
                  Create
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerCreationModal;
