import React from "react";

/**
 * A checkbox input field with bootstrap styling for use in a form.
 * 
 * @param id Unique identifier for this input field
 * @param label A <label> element is used to display the text in @label
 * @param value useState getter managed by parent
 * @param setValue useState setter managed by parent
*/
export default function Checkbox({
	id,
	label,
	value,
	setValue
}: ICheckbox){

	const handleChange = () => setValue(!value);

	return (
        <div className="form-check">
            <input
				key={id}
				id={id}
                className="form-check-input"
                type="checkbox"
				checked={value}
				onChange={handleChange}
                />
            <label
                className="form-check-label"
                htmlFor={id}
                >
                {label}
            </label>
        </div>
	);

}

interface ICheckbox{
    id: string;
	label: string;
	value: boolean;
	setValue: (checked: boolean) => void;
}