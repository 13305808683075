import { useEffect, useState, useCallback } from "react";
import { DateTime, Duration } from "luxon";
import { ITodaysLogsViewModel } from "./TodaysLogsViewModel.ts";
import NewLog from "../classes/NewLog.ts";

export default function RunningLogViewModel(args: IRunningLog): IRunningLogViewModel {
    const { model } = args;
    
    const defaultDuration = Duration.fromObject({ seconds: 0 });

    const [id, setId] = useState<number>(-1);
    
    const [total, setTotal] = useState<Duration>(defaultDuration);
    const [lastResumed, setLastResumed] = useState<DateTime | null>(null);
    const [display, setDisplay] = useState<string>("0:00:00");
    const [runningSeconds, setRunningSeconds] = useState(0);

    const start = (newLog: NewLog) => {
        setId(newLog.id);
    
        setTotal(newLog.total);
        setRunningSeconds(newLog.total.as("seconds"));
        setDisplay(newLog.total.toFormat("h:mm:ss"));
        setLastResumed(DateTime.now());
    };

    const updateLogRefs = (id: number) => {
        const index = model.logs.findIndex(log => log.id === id);
        let log = model.logs[index];
        log.total = Duration.fromObject({ seconds: runningSeconds });
        log.start = DateTime.now().minus(log.total);
        log.finish = DateTime.now();
    
        if (model.activeLog.id === id) {
            model.activeLog.setTotal(log.total);
        }
    
        return log;
    };

    const saveLog = (id: number, success) => {
        const log = updateLogRefs(id);
        
        model.save(log, (msg) => console.error("Save Error: " + msg), success);
    }

    const pause = () => {
        if (!lastResumed || id === -1) {
            return { log: null };
        }



        updateLogRefs(id);

        setId(-1);
        setTotal(defaultDuration);
        setRunningSeconds(0);
        setLastResumed(null);

        return {log: null};
    };
    
    useEffect(() => {
        if (id === -1 ) 
            return;

                   
        if (Math.floor(runningSeconds) % 30 === 0) {
            const success = () => {
                setTotal(Duration.fromObject({ seconds: runningSeconds }));
                setLastResumed(DateTime.now());
            };
            saveLog(id, success);
        }

    }, [id, runningSeconds]);

    useEffect(() => {
        const combinedInterval = setInterval(() => {
            if (!lastResumed || id === -1) return;

            const now = DateTime.now();
            const elapsedSeconds = now.diff(lastResumed).as("seconds");
            const updatedTotal = total.plus({ seconds: elapsedSeconds });

            setRunningSeconds(updatedTotal.as("seconds"));
            setDisplay(updatedTotal.toFormat("h:mm:ss"));
        }, 1000);
        
        return () => {
            clearInterval(combinedInterval);
        };
    }, [lastResumed, total, id, runningSeconds]);

    return {
        id,
        display,
        runningSeconds,
        start,
        pause
    };
}

export interface IRunningLogViewModel {
    id: number;
    display: string;
    runningSeconds: number;
    start: (log: NewLog) => void;
    pause: () => { log: NewLog | null };
}

interface IRunningLog {
    model: Partial<ITodaysLogsViewModel> & Required<Pick<ITodaysLogsViewModel, 'save' | 'logs' | 'activeLog'>>;
}
