import { useState, useEffect } from 'react';
import User from '../../js/classes/User.ts';
import CustomerGroup from '../../js/classes/CustomerGroup.ts';

export default function UserTableViewModel(): IUserTableViewModel {
  const [users, setUsers] = useState<Array<User>>([]);
  const [allUsers, setAllUsers] = useState<Array<User>>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<User>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showErrorDiv, setShowResponseDiv] = useState<boolean>(false);
  const [actionResponse, setResponseAction] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [selectAll, setSelectAll] = useState<boolean>(false); // Track the "Select All" checkbox
  const [groups, setGroups] = useState<CustomerGroup[]>([]);
  const [callbackAfterUpdate, setCallbackAfterUpdate] = useState<((users: Array<User>) => void) | null>(null);


  useEffect(() => {
    if (callbackAfterUpdate) {
      callbackAfterUpdate(allUsers);
    }
  }, [allUsers, callbackAfterUpdate]); 


  // Global setUsers with callback
  const setUsersGlobal = (newUsers: Array<User>, callback?: (users: Array<User>) => void) => {
    setUsers(newUsers);
    setAllUsers(newUsers); 

    if (callback) {
      setCallbackAfterUpdate(() => callback);
    }
  };

  // Handle user update
  const handleUserUpdate = (updatedUsers: User[], isSuccess: boolean, message?: string) => {
    if (isSuccess && updatedUsers.length > 0) {
      const updatedState = users.map(user => {
        const updatedUser = updatedUsers.find(u => u.u_name === user.u_name);
        return updatedUser ? updatedUser : user;
      });
      setUsersGlobal(updatedState);
      handleGenericCallback(true, message);
    } else {
      handleGenericCallback(false, message);
    }
    setSelectedUsers([]); // Reset selected users after update
  };

  // Filter Users
  const filterUsers = (searchTerm: string) => {
    const filtered = allUsers.filter(
      (user) =>
        user.u_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.u_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (user.u_groups && user.u_groups.join(", ").toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setUsers(filtered);
  };

  // Select User
  const handleSelectUser = (user: User) => {
    const selectedUser = new User(user);
    // Toggle user selection
    if (
      selectedUsers.some((selectedUser) => selectedUser.u_name === user.u_name)
    ) {
      setSelectedUsers(
        selectedUsers.filter(
          (selectedUser) => selectedUser.u_name !== user.u_name
        )
      );
    } else {
      setSelectedUsers([...selectedUsers, selectedUser]);
    }
};

  // Select/Deselect All Users
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedUsers([]); // Deselect all users
    } else {
      setSelectedUsers(users.map((user) => user)); // Select all users
    }
    setSelectAll(!selectAll); // Toggle "Select All" state
  };


  // Add New User Callback
  const addNewUserCallback = (isSuccess: boolean, user?: User, message?: string) => {
    if (user instanceof User) {
        setUsersGlobal([...users, user]);
    }
    handleGenericCallback(isSuccess, message);
  };

  // Handle Generic Callback
  const handleGenericCallback = (isSuccess: boolean, message?: string) => {
    if (isSuccess) {
      setResponseMessage("");
      setShowResponseDiv(true);
      setResponseAction(true);
    } else if (message) {
      setResponseMessage(message);
      setShowResponseDiv(true);
      setResponseAction(false);
    }
  };
  const handleUserDeleteCallback = (isSuccess: boolean, message?: string, userdetails?: User) => {
    if (isSuccess && userdetails) {
      
      const result = allUsers.filter((user) => user.u_name !== userdetails.u_name);
      
      setUsersGlobal(result);
    }
    handleGenericCallback(isSuccess, message);
  };

  return {
    users,
    groups,
    setGroups,
    setUsers,
    allUsers,
    setAllUsers,
    selectedUsers,
    setSelectedUsers,
    loading,
    setLoading,
    error,
    setError,
    showErrorDiv,
    setShowResponseDiv,
    actionResponse,
    setResponseAction,
    responseMessage,
    setResponseMessage,
    filterUsers,
    handleSelectUser,
    handleUserUpdate,
    handleSelectAll,
    addNewUserCallback,
    setUsersGlobal,
    handleUserDeleteCallback
  };
}

export interface IUserTableViewModel {
  users: Array<User>;
  groups: Array<CustomerGroup>;
  setGroups: (value: Array<CustomerGroup>) => void;
  setUsers: (value: Array<User>) => void;
  allUsers: Array<User>;
  setAllUsers: (value: Array<User>) => void;
  selectedUsers: Array<User>;
  setSelectedUsers: (value: Array<User>) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  error: string | null;
  setError: (value: string | null) => void;
  showErrorDiv: boolean;
  setShowResponseDiv: (value: boolean) => void;
  actionResponse: boolean;
  setResponseAction: (value: boolean) => void;
  responseMessage: string;
  setResponseMessage: (value: string) => void;
  filterUsers: (searchTerm: string) => void;
  handleSelectUser: (user: User) => void;
  handleUserUpdate: (updatedUsers: User[], isSuccess: boolean, message?: string) => void;
  handleSelectAll: (selectAll: boolean) => void;
  addNewUserCallback: (isSuccess: boolean, user?: User, message?: string) => void;
  handleUserDeleteCallback: (isSuccess: boolean, message?: string, userdetails?: User) => void;
  setUsersGlobal: (users: Array<User>, callback?: (users: Array<User>) => void) => void; // Updated with optional callback
}
