import { Dispatch, SetStateAction, useState } from 'react';
import  User  from '../classes/User.ts';
import NewLog, {INewLog} from '../classes/NewLog.ts';
import Customer from '../classes/Customer.ts';
import AjaxFn, { SaveLogRequest } from '../util/AjaxFn.ts';
import RunningLogViewModel, { IRunningLogViewModel } from './RunningLogViewModel.ts';
import ActiveLogViewModel, { IActiveLogViewModel } from './ActiveLogViewModel.ts';

export default function TodaysLogsViewModel(): ITodaysLogsViewModel {

    const [user, setUser] = useState<User>({u_name: ''} as User);
    const [logs, setLogs] = useState<Array<NewLog>>([]);
    const [customers, setCustomers] = useState<Array<Customer>>([]);
    const [customer, setCustomer] = useState('');
    const [runningTotal, setRunningTotal] = useState('');
    const [requestInProgress, setRequestInProgress] = useState<boolean>(false);
    const activeLog = ActiveLogViewModel();


    const pause = (
        error: (msg: string) => void,
        success: () => void
    ) => {

        runningLog.pause();
        const id = runningLog.id;
        
        if (runningLog.id === -1) {
            success();
            return;
        }


        const errCallback = () => {
            console.error("Failed to find log, during pause");
            error("Request Failed. Failed to find log");
            // success();
        };

        getLogById(id, errCallback, ({ index, log }) => {

            save(log, (msg) => {
                console.error("Saving log Error: " + msg);
                error(msg);
            }, () => {


                setLogs([
                    ...logs.slice(0, index),
                    log,
                    ...logs.slice(index + 1)
                ]);

                success();

            });

        });
        
    };
    
    const resume = (
        log: NewLog,
        error: (msg: string) => void
    ) => {

        pause(error, () => runningLog.start(log));

    };

    const getLogById = (
        id: number,
        error: () => void,
        success: (result: ILogAndIndex) => void
    ) => {

        var index = logs.map(l => l.id).indexOf(id);
        if (index === -1) {
            error();
            return;
        }

        var log = logs[index];
        success({ index, log });

    };

    const recent = (customer: Customer) => {
        // TODO: show recent logs for this customer
    }

    const buildSaveLogRequest = (
        activeLog: NewLog,
        error: (msg: string) => void,
        callback: () => void,
    ): SaveLogRequest => {


        return {
            user: user.u_name,
            id: activeLog.id,
            prb: activeLog.prb,
            caller: activeLog.caller,
            total: Math.floor(activeLog.total.as('seconds')),
            cust: activeLog.customer.code,
            problem: activeLog.problem,
            solution: activeLog.solution,
            // send as ISO backend knows what todo
            start: activeLog.start.toISO(),
            finish: activeLog.finish.toISO(),
            callback,
            errCallback: (err) => error(err.data),
            setRequestInProgress
        }
    };

    const save = (
        log: NewLog,
        error: (msg: string) => void,
        success: () => void
        ) => {

        const errCallback = () => {
            // Log doesn't exist, just pretend it was successful
            // console.log(`Log ${log.id} doesn't exist`);
            console.error("error occured during log saving");
            error("Request Failed. Failed to save log");
            // success();
        };

        
        getLogById(log.id, errCallback, ({ index }) => {

            const args = buildSaveLogRequest(log, error, () => {
                setLogs([
                    ...logs.slice(0, index),
                    log,
                    ...logs.slice(index + 1)
                ]);
                success();
            });
            AjaxFn.saveLog(args);

        });

    };

    
    const submitLog = (
        Log: NewLog,
        error: (msg: string) => void,
        success: () => void
    ) => {
        // console.log(Log);
        if (!Log.id){
            error("No Log Found to submit");
            return;
        }

        
        if (requestInProgress) {
            console.log("Request already in progress");
            return;
        }
    
        // setRequestInProgress(true);
            const errCallback = () => {
                // Log doesn't exist, just pretend it was successful
                // console.log(`Log ${Log.id} doesn't exist`);
                // success();
                console.error("an error occurred, during log submission");
                error("Request Failed. Failed to submit log ");
            };

            getLogById(Log.id, errCallback, ({ index }) => {

                const args = buildSaveLogRequest(Log, error, () => {
                    Log.markAsCompleted();
                    success();
                });

                AjaxFn.submitLog(args);
            });    

    };

    const add = (cust: Customer, error: (msg: string) => void) => {
        pause(error, () => {
            AjaxFn.addLog({
                user: user.u_name,
                cust: cust.code,
                callback: (id: number) => {
                    const newLog = new NewLog(cust, { l_id: id } as INewLog);
    
                    setLogs((prevLogs) => {
                        const updatedLogs = [...prevLogs, newLog];
    
                        // Set activeLog after the logs state has been updated
                        activeLog.setLog(newLog);
                        runningLog.start(newLog);
    
                        return updatedLogs;
                    });
                },
                errCallback: (err) => error(err.data),
                setRequestInProgress,
            });
        });
    };

    const removeLog = (
        error: (msg: string) => void
    ) => {

        const log = activeLog.deleteLog();
        const errCallback = () => {
            console.error("Failed to find log, during removal");
            error("Request Failed. Failed to find log to remove");
        }

        getLogById(log.id, errCallback, ({ index }) => {

            const args = buildSaveLogRequest(log, errCallback, () => {

                var newLength = logs.length - 1;
                var newLog: NewLog | null;
                if (newLength > index) {
                    newLog = logs[index + 1];
                } else if (index > 0) {
                    newLog = logs[index - 1];
                } else {
                    newLog = null;
                }
                setLogs([
                    ...logs.slice(0, index),
                    ...logs.slice(index + 1)
                ]);

                activeLog.setLog(newLog);

            });
            
            AjaxFn.saveAndDeleteLog(args);

        });

    };
    const runningLog = RunningLogViewModel({ model: { logs, setLogs, save, activeLog } });

    return {
        user, setUser,
        logs, setLogs,
        customers, setCustomers,
        customer, setCustomer,
        runningTotal, setRunningTotal,
        pause, resume,
        requestInProgress,
        recent,
        save,
        add,
        submitLog,
        removeLog,
        runningLog, activeLog
    };

}

export interface ITodaysLogsViewModel {
    user: User;
    setUser: (value: User) => void;
    customer: string;
    setCustomer: (value: string) => void;
    runningTotal: string;
    setRunningTotal: (value: string) => void;
    customers: Array<Customer>;
    setCustomers: Dispatch<SetStateAction<Array<Customer>>>;
    logs: Array<NewLog>;
    setLogs: (logs: Array<NewLog>) => void;
    requestInProgress: boolean;
    pause: (error: (msg: string) => void, success: () => void) => void;
    recent: (cust: Customer) => void;
    save: (
        log: NewLog,
        error: (msg: string) => void,
        success: () => void
        ) => void;
    submitLog: (
        log: NewLog,
        error: (msg: string) => void,
        success: () => void
    ) => void;
    add: (cust: Customer, error: (msg: string) => void) => void;
    removeLog: (error: (msg: string) => void) => void;
    runningLog: IRunningLogViewModel;
    activeLog: IActiveLogViewModel;
    resume: (log: NewLog, error: (msg: string) => void) => void;
}

interface ILogAndIndex {
    index: number;
    log: NewLog;
}
