import React, { useState } from "react";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import ErrorDiv from "../../common/ErrorModal";
import User from "../../../js/classes/User.ts";
import IconButton from "../../IconButton.tsx"
import { PersonX      } from 'react-bootstrap-icons';

interface UserDeleteModalProps {
  user: User;
  callback: (isSuccess: boolean, message?: string, user?: User) => void;
}

const UserDeleteModal: React.FC<UserDeleteModalProps> = ({ user, callback }) => {
  const [show, setShow] = useState(false);
  const [enteredName, setEnteredName] = useState("");
  const [validated, setValidated] = useState(false);
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const handleClose = (isSuccess: boolean, message?: string, user?: User) => {
    setShow(false);

    callback(isSuccess, message, user);
    // reset validation state
    setEnteredName("");
    setShowResponseDiv(false);
    setValidated(false);
  };

  const handleShow = () => setShow(true);

  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
    setIsSuccess(!message);
  };

  const isNameValid = (name: string) => {
    // Basic validation rules for a valid name
    const namePattern = /^[a-zA-Z\s]+$/; // Adjust this regex pattern as per your rules
    return namePattern.test(name);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const nameMatches = enteredName === user.u_name;
    const nameIsValid = isNameValid(enteredName);

    if (!nameMatches || !nameIsValid) {
      setValidated(true);
      // displayError("The entered name is invalid or does not match the user's name.");
    } else {
      try {
        const url = `/api/users/delete/`;
        const request = { name: user.u_name };
        const result = await HttpClientUtil.deleteRequest(url, request);

        if (!result.success) {
          displayError(result.message);
        } else {
          displayError("");
          handleClose(true, result.message, user);
        }
      } catch (error) {
        console.error("Error deleting user:", error);
        displayError("An unexpected error occurred while deleting the user.");
      }
    }
  };

  return (
    <>
      <IconButton
                btnStyle="btn btn-danger mx-1 btn-sm"
                text="Delete User"
                onClick={handleShow}
                icon={<PersonX   />}
                show={true}
                /> 
      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete User: {user.u_name}</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>

              <form noValidate onSubmit={handleSubmit}>
                <div className="modal-body">
                  <ErrorDiv
                    success={isSuccess}
                    errorMessage={responseMessage}
                    isVisible={showErrorDiv}
                    onClose={() => setShowResponseDiv(false)}
                  />
                  <p>To confirm deletion, please enter the user's name:</p>
                  <br></br>
                  <div className="form-group mb-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={`form-control ${validated && (enteredName !== user.u_name || !isNameValid(enteredName)) ? "is-invalid" : ""}`}
                        placeholder="Enter user's name"
                        value={enteredName}
                        onChange={(e) => setEnteredName(e.target.value)}
                        required
                      />
                      <label htmlFor="confirmName">Enter user's name</label>
                      <div className="invalid-feedback">
                        The name entered must match the user's name and be valid.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => handleClose(false)}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-danger">
                    Delete User
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDeleteModal;
