import { useState, Dispatch, SetStateAction} from "react";
import { useLocalStorage } from "usehooks-ts";
import NewLog from "../classes/NewLog.ts";
import Customer from "../classes/Customer";
import { ITodaysLogsViewModel } from "./TodaysLogsViewModel.ts";
import { parseFlexibleTime } from "../util/timeParse.ts";
import { DateTime, Duration } from "luxon";
import { IUserSettings } from "./UserSettingsViewModel.ts";

export default function ActiveLogModalViewModel(): IActiveLogModalViewModel {
    const [userSettings] = useLocalStorage <Partial< IUserSettings>>("userSettings", {
        defaultLogEditFormat: "hh:mm",
    });

    const defaultTimeFormat = userSettings.defaultLogEditFormat ?? "hh:mm";

    const [log, setLog] = useState<NewLog | null>(null);
    const [solution, setSolution] = useState<string>("");
    const [problem, setProblem] = useState<string>("");
    const [prb, setPrb] = useState<string | null>(null);
    const [caller, setCaller] = useState<string>("");
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [customers, setCustomers] = useState<Array<Customer>>([]);
    const [date, setDate] = useState<string>("");
    const [timeTaken, setTimeTaken] = useState<string>("");

    const show = (newLog: NewLog) => {
        setSolution(newLog.solution);
        setProblem(newLog.problem);
        setCaller(newLog.caller);
        setCustomer(newLog.customer);
        setPrb(newLog.prb);
        setDate(newLog.start.toFormat("yyyy-MM-dd"));
        setTimeTaken(newLog.total.toFormat(defaultTimeFormat));
        setLog(newLog);
    };


    const cancel = () => setLog(null);

    const submit = (model: ITodaysLogsViewModel, error: (msg: string) => void) => {
        if (log === null) {
            error("Log is required");
            cancel();
            return;
        }
        if (customer === null) {
            error("Customer is required");
            cancel();
            return;
        }
        if (!timeTaken) {
            error("Time taken is required");
            cancel();
            return;
        }
        if (!date) {
            error("Date is required");
            cancel();
            return;
        }
    
        


        
        let validParsedTime: Duration | null = null;
        


        validParsedTime = parseFlexibleTime(timeTaken);

        
        // if (validParsedTime) {
        //     console.log('Parsed Duration:', validParsedTime.toObject());
        // } else {
        //     console.error('Invalid time format:', timeTaken);
        // }
        
        if (!validParsedTime) {
            error("Invalid date format");
            cancel();
            return;
        }
        
        const startDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
        if (!startDate.isValid) {
            error("Invalid date format");
            cancel();
            return;
        }
        
        // Calculate total seconds from the Duration object
        const totalSeconds = validParsedTime.as('seconds');


        const now = DateTime.local();
    
        // Calculate the starting time


        //so to make these "LOOK REAL" I'm aussming 
        // noone is going to be doing this outside work hours so
        // lets use there current time 
        
        
        let startTime;
        if (startDate.hasSame(now, 'day', {})) {
            // If the date is today, use the current time minus the time taken
            startTime = now.minus({ seconds: totalSeconds });
        } else {
            // If the date is in the past, adjust the start date to account for the total time
            const secondsToday = now.hour * 3600 + now.minute * 60 + now.second;
            startTime = startDate.plus({ seconds: secondsToday }).minus({ seconds: totalSeconds });
        }
        
        // Ensure startTime is within the bounds of the startDate
        const startOfDay = startDate.set({ hour: 0, minute: 0, second: 0 });
        if (startTime < startOfDay) {
            const secondsFromEndOfDay = 24 * 3600 - totalSeconds;
            startTime = startOfDay.plus({ seconds: secondsFromEndOfDay });
        }
        

        let finish = startTime.plus({ seconds: totalSeconds });
        
        // if (!startTime.isValid || !finish.isValid) {
        //     throw new Error('Invalid startTime or finish time');
        // }
    

       const newLog = new NewLog(customer, {
            l_id: log.id,
            l_start: startTime.toFormat('yyyy-MM-dd HH:mm:ss'),
            l_finish: finish.toFormat('yyyy-MM-dd HH:mm:ss'),
            l_total: totalSeconds,
            l_cust: customer.code,
            // if customer is PRB and PRB number present else null 
            l_prb: (customer.isPrb() && prb) ? prb : null,
            l_caller: caller,
            l_problem: problem,
            l_solution: solution
        });

        // console.table(newLog);

        model.save(newLog, error, () => {
            model.activeLog.setLog(newLog)
            cancel();
        });
    };

    return {
        log,
        show,
        cancel,
        submit,
        prb, setPrb,
        solution, setSolution,
        problem, setProblem,
        customer, setCustomer,
        caller, setCaller,
        date, setDate,
        timeTaken, setTimeTaken,
        customers, setCustomers
    };
}

export interface IActiveLogModalViewModel {
    log: NewLog | null;
    show: (log: NewLog) => void;
    submit: (model: ITodaysLogsViewModel, error: (msg: string) => void) => void;
    cancel: () => void;
    solution: string;
    setSolution: (value: string) => void;
    problem: string;
    setProblem: (value: string) => void;
    customer: Customer | null;
    customers: Array<Customer>;
    setCustomer: Dispatch<SetStateAction<Customer | null>>;
    prb : string | null;
    setPrb: (value: string) => void;
    caller: string;
    setCaller: (value: string) => void;
    date: string;
    setDate: (value: string) => void;
    timeTaken: string;
    setTimeTaken: (value: string) => void;
    // setDefaultTimeFormat: (value: string) => void;
    setCustomers: Dispatch<SetStateAction<Array<Customer>>>;
}
