import { Dispatch, SetStateAction, useState } from "react";
import ArchivedLog from "../classes/ArchivedLog";

export default function ArchivedLogModalViewModel(): IArchivedLogModalViewModel{

    const [log, setLog] = useState<ArchivedLog | null>(null);

    const hide = () => setLog(null);
    return {
        log, setLog,
        hide
    }
}

export interface IArchivedLogModalViewModel{
    log: ArchivedLog | null;
    setLog: Dispatch<SetStateAction<ArchivedLog | null>>;
    hide: () => void;
}