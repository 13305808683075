import React from "react";
import FormComponent from "./FormComponents";
import { basicInfoFields, siteDetailsFields, billingDetailsFields } from "./fields";


export const BasicInfoForm = ({ customerDetails }) => {
  // const [showModal, setShowModal] = useState(false);

  return (
    <FormComponent
      title="Basic Information"
      fields={basicInfoFields}
      customerDetails={customerDetails}
    >
    </FormComponent>
  );
};

export const SiteDetailsForm = ({ customerDetails }) => {
  return (
    <FormComponent
      title="Site Details"
      fields={siteDetailsFields}
      customerDetails={customerDetails}
    />
  );
};

export const BillingDetailsForm = ({ customerDetails, customerOptions }) => {

  return (
    <FormComponent
      title="Billing Details"
      fields={billingDetailsFields}
      customerDetails={customerDetails}
      customerOptions={customerOptions}
    />
  );
};


