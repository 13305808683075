import React, { useState, useEffect } from "react";

const CommentModal = ({ showModal, setShowModal, comment, saveComment }) => {
  const [editedComment, setEditedComment] = useState(
    comment || { C_TYPE: "", C_COMMENT: "" }
  );
  const [templateDescription, setTemplateDescription] = useState("");
  const [isTemplateSave, setIsTemplateSave] = useState(false);

  useEffect(() => {
    setEditedComment(comment || { C_TYPE: "", C_COMMENT: "" });
  }, [comment]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedComment((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (editedComment.C_TYPE && editedComment.C_COMMENT) {
      saveComment(editedComment);
      setShowModal(false);
    } else {
      alert("Both Type and Comment must be filled out.");
    }
  };

  const handleTemplateSave = () => {
    if (editedComment.C_TYPE && editedComment.C_COMMENT) {
      setIsTemplateSave(true);
    } else {
      alert("Both Type and Comment must be filled out.");
    }
  };

  const handleTemplateDescriptionSave = () => {
    if (templateDescription.trim()) {
      saveComment({ ...editedComment, templateDescription }, true);
      setTemplateDescription('');
      setShowModal(false);
      setIsTemplateSave(false);
    } else {
      alert("Please provide a description for the template.");
    }
  };

  if (!showModal) return null;

  return (
    <div
      className="modal show d-block"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {comment.C_COMMENT ? "Edit Comment" : "Add Comment"}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowModal(false)}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Type"
              name="C_TYPE"
              value={editedComment.C_TYPE}
              onChange={handleChange}
            />
            <textarea
              className="form-control"
              placeholder="Comment"
              name="C_COMMENT"
              value={editedComment.C_COMMENT}
              onChange={handleChange}
            />
            {isTemplateSave && (
              <div className="mt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Template Description"
                  value={templateDescription}
                  onChange={(e) => setTemplateDescription(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="modal-footer">
            {isTemplateSave ? (
              <>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleTemplateDescriptionSave}
              >
                Save Template
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={()=>{setIsTemplateSave(false)}}
              >
                Cancel Template
              </button> 
          
              </>
          ) : (
              <>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleTemplateSave}
                >
                  Save & Template
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
                >
                  Save Comment
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentModal;
