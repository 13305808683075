import React, { useState } from "react";
import { IAdminScreenViewModel } from "../js/viewmodels/AdminScreenViewModel.ts";
import { Collapse, Button, Form, Table } from "react-bootstrap";
import {
  FaChevronDown,
  FaChevronUp,
  FaEnvelope,
} from "react-icons/fa";
import ConfirmationModal from "./common/ConfirmationModal.jsx";
import ErrorDiv from "./common/ErrorModal.jsx";
import LoadingScreen from "./common/loading.tsx";
import AskfordataModal from "./common/AskforEndofPeriod.jsx";

import CustomerItem from "./admin/customeritems.tsx";
const AdminPanel: React.FC<IAdminScreen> = (args: IAdminScreen) => {
  const { model } = args;

  const groupedCustomers = model.customers.reduce((acc, customer) => {
    const group = customer.group || "Ungrouped";
    if (!acc[group]) acc[group] = [];
    acc[group].push(customer);
    return acc;
  }, {} as Record<string, any[]>);

  const ungrouped = groupedCustomers["Ungrouped"] || [];
  const uniqueHeads = new Set();

  for (let customer of model.customers) {
    const group = customer.group;
    if (group && !uniqueHeads.has(group)) {
      uniqueHeads.add(group);

      const customerHeadIndex = ungrouped.findIndex(
        (cust) => cust.code === group
      );

      if (customerHeadIndex !== -1) {
        groupedCustomers[group].unshift(ungrouped[customerHeadIndex]);
        ungrouped.splice(customerHeadIndex, 1);
      }
    }
  }

  return (
    <>
      <ErrorDiv
        success={model.isSuccess}
        errorMessage={model.responseMessage}
        isVisible={model.showErrorDiv}
        onClose={() => model.setShowResponseDiv(false)}
      />
      <ConfirmationModal
        show={model.showConfirmModal}
        onConfirm={model.handleConfirm}
        onCancel={() => model.setShowConfirmModal(false)}
        title="Confirm End of Month"
        message="Are you sure you want to run End of Month processes?"
      />
      <AskfordataModal
        show={model.showEndOfMonthModal}
        onConfirm={model.endOfMonth}
        onCancel={() => model.setShowEndOfMonthModal(false)}
        Title="Enter the End of Month Period"
      />
      <LoadingScreen loading={model.showLoadingScreen} />
      <div className="card">
        <div className="card-header">Admin Panel</div>
        <div className="card-body">
          <h5 className="card-title">End of Month Actions</h5>
          <button
            onClick={model.handleEndOfMonthPrompt}
            className="btn btn-danger"
          >
            Run End of Month
          </button>
        </div>
      </div>
      {/* dev code for now */}
      {/* <div className="card mt-3">
        <div className="card-header">
          <h5>Customer Management</h5>
        </div>
        <div className="card-body">
          {Object.keys(groupedCustomers).map((group) => (
            <CustomerGroup
              key={group}
              group={group}
              customers={groupedCustomers[group]}
            />
          ))}
        </div>
      </div> */}
    </>
  );
};

// Component to display each group of customers
const CustomerGroup: React.FC<{ group: string; customers: any[] }> = ({
  group,
  customers,
}) => {
  const [open, setOpen] = useState(false); 
  const [useDefaultEmail, setUseDefaultEmail] = useState(true);
  const [emailSubject, setEmailSubject] = useState<string>('');
  // the more I think about this we can't realisticly do an email...
  // const [emailAddress, SetEmailAddress] = useState<string>('');
  const [emailContent, setEmailContent] = useState<string>("");

  const handleSendAll = () => {
    // Logic to send email to all customers in the group
    console.log(`Sending email to all customers in ${group}: ${emailContent}`);
  };

  return (
    <div className="customer-group mb-3">
      <Button
        variant="link"
        className="group-header"
        onClick={() => setOpen(!open)}
        aria-controls={`collapse-${group}`}
        aria-expanded={open}
      >
        {open ? <FaChevronUp /> : <FaChevronDown />} {group} ({customers.length}{" "}
        customers)
      </Button>

      <Collapse in={open}>
        <div id={`collapse-${group}`} className="group-body mt-2">
          <Form.Check
            type="checkbox"
            label="Use Default Email"
            checked={useDefaultEmail}
            onChange={() => setUseDefaultEmail(!useDefaultEmail)}
          />
          {!useDefaultEmail && (
            <Form.Group controlId={`emailForm-${group}`} className="mb-2">
              <Form.Label>
                <FaEnvelope /> Custom Email Content
              </Form.Label>
              {/* <Form.Control
                as="input"
                value={emailAddress}
                onChange={(e) => SetEmailAddress(e.target.value)}
                placeholder="Enter your email subject here..."
                className="mb-2"
              />               */}
              <Form.Control
                as="input"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
                placeholder="Enter your email subject here..."
                className="mb-2"
              />
              <Form.Control
                as="textarea"
                rows={3}
                value={emailContent}
                onChange={(e) => setEmailContent(e.target.value)}
                placeholder="Enter your email message here..."
                className="mb-2"
              />
            </Form.Group>
          )}
          <Button
            variant="primary"
            onClick={handleSendAll}
            disabled={!useDefaultEmail && !emailContent && !emailSubject}
            className="mb-3"
          >
            Send All
          </Button>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Code</th>
                <th>Customer Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <CustomerItem key={customer.code} customer={customer} />
              ))}
            </tbody>
          </Table>
        </div>
      </Collapse>
    </div>
  );
};



export default AdminPanel;

interface IAdminScreen {
  model: IAdminScreenViewModel;
}
