export interface IUser {
  u_name: string;
  u_email: string;
  u_groups?: string[];
}

export default class User implements IUser {
  u_name: string;
  u_email: string;
  u_groups: string[];

  constructor(user: IUser) {
      this.u_name = user.u_name;
      this.u_email = user.u_email;
      this.u_groups = user.u_groups || [];
  }

  addGroup(group: string): void {
      if (!this.u_groups) {
          this.u_groups = [];
      }
      if (!this.u_groups.includes(group)) {
          this.u_groups.push(group);
      }
  }


  removeGroup(group: string): void {
      if (!this.u_groups) {
          this.u_groups = [];
      }
      if (this.u_groups) {
          this.u_groups = this.u_groups.filter(g => g !== group);
      }
  }

  updateEmail(newEmail: string): void {
      this.u_email = newEmail;
  }

  renameUser(newName: string): void {
      this.u_name = newName;
  }
  static createEmptyUser() {
      return new User({
          u_name: "",
          u_email: "",
          u_groups: [], 
      });
  }
}
