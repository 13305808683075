import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";

interface CustomerSearchBarProps {
  customerData: any[];
  handleCustomerSelection: (dialCode: string) => void;
  handleSearch: (query: string) => void;
  customer: string;
  setCustomer: React.Dispatch<React.SetStateAction<string>>;
  handleRefresh: (bool: boolean) => void;
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveCustomerDetails: React.Dispatch<React.SetStateAction<Partial<any>>>;
}

const CustomerSearchBar: React.FC<CustomerSearchBarProps> = ({
  customerData,
  handleCustomerSelection,
  handleSearch,
  customer,
  setCustomer,
  handleRefresh,
  setShowTable,
  setActiveCustomerDetails,
}) => {
  return (
    <div className="mb-4">
      <Typeahead
        id="notesCustomer"
        labelKey="label"
        options={customerData.map((item) => ({
          label: `${item.C_DIALCODE} - ${item.C_NAME}`,
          value: item.C_DIALCODE,
        }))}
        onChange={(selected) => {
          if (selected.length > 0) {
            handleCustomerSelection(selected[0].value);
          } else {
            handleRefresh(false);
            setShowTable(true);
            setCustomer("");
            setActiveCustomerDetails({});
          }
        }}
        onInputChange={handleSearch}
        placeholder="Select a customer..."
        selected={
          customer
            ? [
                {
                  label: `${customerData.find(
                    (item) => item.C_DIALCODE === customer
                  )?.C_DIALCODE} - ${customerData.find(
                    (item) => item.C_DIALCODE === customer
                  )?.C_NAME}`,
                  value: customer,
                },
              ]
            : []
        }
        clearButton
        className="form-control"
      />
    </div>
  );
};

export default CustomerSearchBar;
