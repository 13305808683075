import React from 'react';
import { DateTime, Duration } from "luxon";

export default class ArchivedLog {
  id: number;
  start: DateTime;
  end: DateTime;
  total: Duration;
  cust: string;
  prb: string | null;
  caller: string;
  problem: string;
  solution: string;
  user: string;
  transhistory: boolean;
  deleted: boolean;

  constructor(val: any) {
    this.id = val.T_ID;
    
    let start;
    try {
      start = DateTime.fromISO(val.T_START, {setZone: true});

      if (!start.isValid) {
          start = DateTime.fromFormat(val.T_START, 'yyyy-MM-dd HH:mm:ss');
      }
    } catch (error) {
        console.error('Error parsing T_FINISH:', error);
        start = null;
    }
    this.start = start;

    let end;
    try {
      end = DateTime.fromISO(val.T_FINISH, {setZone: true});

      if (!end.isValid) {
            end = DateTime.fromFormat(val.T_FINISH, 'yyyy-MM-dd HH:mm:ss');
        }
    } catch (error) {
        console.error('Error parsing T_FINISH:', error);
        end = null;
    }

    let differenceInSeconds = val.T_MINUTES * 60;
    if (end) {
      differenceInSeconds = end.diff(this.start, 'seconds').seconds;

    } else {
        end = this.start.plus({ seconds: differenceInSeconds });
    }

    this.end = end;
    this.total = Duration.fromObject({
        seconds: differenceInSeconds
    });

    this.cust = val.T_DIALCODE;
    this.prb = val.T_PRB || null;
    this.caller = val.T_CALLER ?? '(NO CALLER)';

    this.problem = (val.T_PROBLEM && val.T_PROBLEM?.trim() !== '') ? val.T_PROBLEM : '(NO PROBLEM)';
    this.solution = (val.T_SOLUTION && val.T_SOLUTION?.trim() !== '') ? val.T_SOLUTION : '(EMPTY)';
    this.user = val.T_OPTR ?? '(NO USER)';
    this.transhistory = !!val.T_HISTORY;
    this.deleted = val.T_DELETED || this.problem.startsWith("(DELETED)");
}

  // Method to format the object into a sendable format for the API
  toSendable(): IArchivedLog {
    return {
      T_ID: this.id,
      T_MINUTES: Math.round(this.total.as('minutes')), 
      // node is weird it automatically converts the date we receive to local
      // so the backend will convert it to UTC
      T_START: this.start.toISO(),
      T_FINISH: this.end.toISO(),  
      T_DIALCODE: this.cust,
      T_PRB: this.prb,
      T_CALLER: this.caller,
      T_PROBLEM: this.problem,
      T_SOLUTION: this.solution,
      T_OPTR: this.user,
      T_HISTORY: this.transhistory,
      T_DELETED: this.deleted 
    };
  }

  displayTime(): string {
    return this.total.toFormat('h:mm:ss');
  }

  getMinutes(): number {
    return this.total.as('minutes');
  }

  setDurationFromMinutes(minutes: number) {
    this.total = Duration.fromObject({
      years: 0,
      quarters: 0,
      months: 0,
      weeks: 0,
      days: 0,
      hours: 0,
      minutes: minutes,
      seconds: 0,
      milliseconds: 0
    });
  }

  getLogStyle(): React.CSSProperties {
    const isDeleted = this.deleted;
    const hasNoCaller = !this.caller;
    const hasNoProblem = this.problem.includes("(NO PROBLEM)");
    const hasNoSolution = this.solution.includes("(EMPTY)");

    const isCriticalIssue = hasNoCaller && hasNoProblem && hasNoSolution;
    const isNoticeableIssue = hasNoProblem && hasNoSolution;

    if (isDeleted) {
        return {
            backgroundColor: 'slategray', 
            color: 'white',
            fontWeight: 'bold',
            textDecoration: 'line-through',
        };
    }
    if (isCriticalIssue) {
        return {
            backgroundColor: '#FF0000', 
            color: 'white',
            fontWeight: 'bold',
        };
    }

    if (isNoticeableIssue) {
        return {
            backgroundColor: '#FFA500', 
            color: 'black',
            fontWeight: 'bold',
        };
    }

    if (hasNoProblem) {
        return {
            backgroundColor: '#FFD700', 
            color: 'black',
        };
    }

    if (hasNoSolution) {
        return {
            backgroundColor: '#ADD8E6', 
            color: 'black',
        };
    }

    return {};
  }
  displayDate(): string {
    return this.start.toFormat('yyyy-MM-dd');
  }

  isDeleted(): boolean {
    return this.deleted;
  }

  compareTo(b: ArchivedLog, order: string): number {
    if (order === 'time') {
        return this.getMinutes() - b.getMinutes(); // Numeric comparison
    }
    return this.getField(order).localeCompare(b.getField(order));
}

  getCustomerName(): string {
    return this.cust;
  }

  getField(key: string): string {
    switch (key) {
      case 'time':
        return this.displayTime();
      case 'cust':
        return this.cust || '';
      case 'user':
        return this.user || '';
      case 'caller':
        return this.caller || '';
      case 'problem':
        return this.problem || '';
      case 'solution':
        return this.solution || '';
      default:
        return this.displayDate();
    }
  }
}

// Interface for the ArchivedLog structure
export interface IArchivedLog {
  T_ID: number;
  T_MINUTES: number;
  T_START: string;
  T_FINISH: string;
  T_DIALCODE: string;
  T_PRB: string | null;
  T_CALLER: string;
  T_PROBLEM: string;
  T_SOLUTION: string;
  T_OPTR: string;
  T_HISTORY: boolean;
  T_DELETED: boolean;
}
