import { Dispatch, SetStateAction, useState } from 'react';
import Customer from '../classes/Customer';
import AjaxFn, { ErrorResponse } from '../util/AjaxFn.ts';

export default function CustomerScreenViewModel(): ICustomerScreenViewModel{

	const [customer, setCustomer] = useState<Customer | null>(null);
	const [publicNote, setPublicNote] = useState<string>('');
	const [privateNote, setPrivateNote] = useState<string>('');
	const [savingCustomerInProgress, setSavingCustomerInProgress] = useState<boolean>(false);

	const [publicGroupNote, setPublicGroupNote] = useState<string>('');
	const [privateGroupNote, setPrivateGroupNote] = useState<string>('');
	const [savingGroupInProgress, setSavingGroupInProgress] = useState<boolean>(false);

	const [customers, setCustomers] = useState<Array<Customer>>([]);

	
	const saveCustomerSettings = (error: (msg: string) => void) => {
		
		if (customer === null) return;



		AjaxFn.saveCustomerNotes({
			cust: customer.code,
			publicNote,
			privateNote,
			callback: () => {
				// reverse match as well
				let possible = customers.filter(c => c.group === customer.code);
				//update possible group
				if (possible){
					possible.forEach(c => {
						c.publicGroupNote = publicNote;
						c.privateGroupNote = privateNote;
					})
				}

				customer.publicNote = publicNote;
				customer.privateNote = privateNote;
			},
			errCallback: (response: ErrorResponse) => {
				error(response.data)
			},
			setRequestInProgress: setSavingCustomerInProgress
		});
	};

	const saveGroupSettings = (error: (msg: string) => void) => {
		
		if (customer === null) return;

		AjaxFn.saveCustomerNotes({
			cust: customer.group,
			publicNote: publicGroupNote,
			privateNote: privateGroupNote,
			callback: () => {

				// console.log(customer);
				let mainCustomer = customers.find(c => c.code === customer.group);

				//update main
				if (mainCustomer) {
					mainCustomer.publicGroupNote = publicGroupNote;
					mainCustomer.privateGroupNote = privateGroupNote;

					mainCustomer.publicNote = publicGroupNote;
					mainCustomer.publicNote = privateGroupNote;
				}
				
				//update group
				let billto = customers.filter(c => c.group === customer.group); 
				billto.forEach(c => {
					c.publicGroupNote = publicGroupNote;
					c.privateGroupNote = privateGroupNote;
				});
			},
			errCallback: (response: ErrorResponse) => {
				error(response.data)
			},
			setRequestInProgress: setSavingGroupInProgress
		});
	};

	const setCustomerHandle = (cust: Customer | null) => {
		setCustomer(cust);
		setPublicNote(cust?.publicNote || '');
		setPrivateNote(cust?.privateNote || '');
		setPublicGroupNote(cust?.publicGroupNote || '');
		setPrivateGroupNote(cust?.privateGroupNote || '');
	}

	return {
		customers, setCustomers,
		customer, 

		publicNote, setPublicNote,
		privateNote, setPrivateNote,
		savingCustomerInProgress, //setSavingCustomerInProgress,
		saveCustomerSettings,
		setCustomerHandle,
		publicGroupNote, setPublicGroupNote,
		privateGroupNote, setPrivateGroupNote,
		savingGroupInProgress, //setSavingGroupInProgress,
		saveGroupSettings
	};

}

export interface ICustomerScreenViewModel{
	savingGroupInProgress: boolean;
	savingCustomerInProgress: boolean;
	customer: Customer | null;
	customers: Array<Customer>;
	privateGroupNote: string;
	setPrivateGroupNote: (note: string) => void;
	publicGroupNote: string;
	setPublicGroupNote: (note: string) => void;
	privateNote: string;
	setPrivateNote: (note: string) => void;
	publicNote: string;
	setPublicNote: (note: string) => void;
	setCustomers: Dispatch<SetStateAction<Array<Customer>>>;
	setCustomerHandle: (cust: Customer | null) => void;

	saveCustomerSettings: (error: (msg: string) => void) => void;
	saveGroupSettings: (error: (msg: string) => void) => void;
}
