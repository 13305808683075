import AjaxUtil from "../../common/js/AjaxUtil.ts";
import ArchivedLog, { IArchivedLog } from "../classes/ArchivedLog.ts";
import Customer, { ICustomer } from "../classes/Customer.ts";
import User from "../classes/User.ts";
import CustomerGroup from "../classes/CustomerGroup.ts";
import NewLog, { INewLog } from "../classes/NewLog.ts";

export default class AjaxFn {

    static async getSession(reqArgs: InitialLoadRequest) {
        const { callback, errCallback, setRequestInProgress } = reqArgs;
    
        // Await resumption of pending requests before proceeding
        await AjaxFn.resumePendingRequest();
    
        const args = { fn: 'get-session' };
    
        const cb = (resp: InitialLoadResponse) => {
            const { user, groups, users, customers, logs, completedlogs } = resp.data;
    
            const custArr = customers.map(c => new Customer(c));
            const logsArr: Array<NewLog> = [];
    
            logs.forEach(l => {
                const cust = custArr.find(c => c.code === l.l_cust);
                if (cust) logsArr.push(new NewLog(cust, l));
            });
    
            // Push the completed logs with a completed flag
            completedlogs.forEach(l => {
                const cust = custArr.find(c => c.code === l.l_cust);
                l.completed = true;
                if (cust) logsArr.push(new NewLog(cust, l));
            });
    
            const mappedUsers = users.map(u => new User(u));
    
            callback(user, groups, mappedUsers, custArr, logsArr);
        };
    
        AjaxFn.postMySql({ args, callback: cb, errCallback, setRequestInProgress });
    }

    static async resumePendingRequest(): Promise<void> {
        const pendingRequest = sessionStorage.getItem('pendingRequest');
    
        if (pendingRequest) {
            console.log("Resuming pending request...");
            const { args } = JSON.parse(pendingRequest);
    
            // Clear the pending request from storage
            sessionStorage.removeItem('pendingRequest');
    
            return new Promise<void>((resolve, reject) => {
                AjaxFn.postMySql({
                    args,
                    callback: () => {
                        console.log("Pending request completed.");
                        resolve();
                    },
                    errCallback: (error) => {
                        console.error("Error resuming pending request:", error);
                        reject(error);
                    },
                    setRequestInProgress: (inProgress) => {
                        console.log(`Pending request in progress: ${inProgress}`);
                    },
                });
            });
        }
    
        console.log("No pending request to resume.");
        return Promise.resolve();
    }

    static getHistoryLogs(reqArgs: HistoryLogsRequest) {
        const { fromDate, toDate, cust, user, callback, errCallback, setRequestInProgress } = reqArgs;

        // Determine the cust code based on whether cust is a string or a Customer object
        const custCode = cust?.code ?? null;
        const userCode = user?.u_name ?? null;



        // Prepare the arguments for the AJAX function
        const args: any = { fn: 'get-all-archived-logs', fromDate, toDate };
        if (custCode) {
            args.cust = custCode;
        }
        if (userCode) {
            args.user = userCode;
        }

        const cb = (resp: ArrayDataResponse<IArchivedLog>) => {
            const logs = resp.data.map(log => new ArchivedLog(log));
            callback(logs);
        };

        AjaxFn.postMySql({ args, callback: cb, errCallback, setRequestInProgress });
    }



    static addLog(reqArgs: AddLogRequest) {

        const { user, cust, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'add-log', cust, user };
        const cb = (resp: AddLogResponse) => {
            callback(resp.data.id);
        };

        AjaxFn.postMySql({ args, callback: cb, errCallback, setRequestInProgress });
    }


    static submitLog(reqArgs: SaveLogRequest) {

        const { user, id, prb, caller, total, cust, problem, solution, start, finish, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'submit-log', user, id, prb, caller, total, cust, problem, solution, start, finish };

        AjaxFn.postMySql({ args, callback, errCallback, setRequestInProgress });

    }

    static saveLog(reqArgs: SaveLogRequest) {

        const { user, id, caller, prb, total, cust, problem, solution, start, finish, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'update-log', user, id, prb, caller, total, cust, problem, solution, start, finish };

        AjaxFn.postMySql({ args, callback, errCallback, setRequestInProgress });

    }

    static saveAndDeleteLog(reqArgs: SaveLogRequest) {

        const { user, id, prb, caller, total, cust, problem, solution, start, finish, callback, errCallback, setRequestInProgress } = reqArgs;
        const args = { fn: 'update-and-delete-log', user, id, prb, caller, total, cust, problem, solution, start, finish };

        AjaxFn.postMySql({ args, callback, errCallback, setRequestInProgress });

    }

    static saveCustomerNotes(reqArgs: saveCustomerNotesRequest) {

        const { cust, publicNote, privateNote, callback, errCallback, setRequestInProgress } = reqArgs;

        const args = { fn: 'set-customer-notes', cust, publicNote, privateNote };
        const cb = (response: BooleanResponse) => {
            if (response.success) {
                callback();
            } else {
                errCallback({ success: false, data: response.data ?? "Request failed" });
            }
        };

        AjaxFn.postMySql<BooleanResponse>({ args, callback: cb, errCallback, setRequestInProgress });

    }

    static postMySql<T>({
        args,
        callback,
        errCallback,
        setRequestInProgress,
    }: MysqlRequest<T>) {
        const url = 'ajax';

        const success = (json: any) => {
            if (json.success === false) {
                const loginUrl = json.loginUrl ?? undefined;
                const data = json.data ?? "Request failed";

                if (loginUrl) {
                    console.log("Reauthentication required. Redirecting...");

                    // Save the original request details
                    sessionStorage.setItem(
                        'pendingRequest',
                        JSON.stringify({ args, url })
                    );

                    window.location.href = loginUrl;
                } else {
                    console.error("Request failed without login URL.");
                    errCallback({ success: false, data });
                }
            } else {
                try {
                    callback(json as T);
                } catch (callbackError) {
                    console.error("Error in callback:", callbackError);
                    errCallback({ success: false, data: "Callback processing failed" });
                }
            }
        };

        const error = (msg: string) => {
            console.error("Error in Ajax request:", msg);
            errCallback({ success: false, data: msg });
        };

        // Perform the AJAX request
        AjaxUtil.fetchPost({ url, args, success, error, setRequestInProgress });
    }

    static postFirebase<T>({
        args,
        callback,
        setRequestInProgress
    }: FirebaseRequest<T>) {
        var url = 'ajax';
        var success = (json: JSON) => callback(json as T);
        AjaxUtil.fetchPost({ url, args, success, setRequestInProgress });
    }

}

interface MysqlRequest<T> {
    args: object;
    callback: (response: T) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface FirebaseRequest<T> {
    args: object;
    callback: (response: T) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface InitialLoadResponse {
    data: {
        user: User;
        groups: Array<CustomerGroup>;
        users: Array<User>;
        customers: Array<ICustomer>;
        logs: Array<INewLog>;
        completedlogs: Array<INewLog>;
    };
}

interface HistoryLogsRequest {
    fromDate: string;
    toDate: string;
    cust: Customer | null;
    user: User | null;
    callback: (response: Array<ArchivedLog>) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface AddLogRequest {
    user: string;
    cust: string;
    callback: (response: number) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface AddLogResponse {
    success: boolean;
    data: {
        id: number;
    };
}

export interface SaveLogRequest {
    user: string;
    id: number;
    prb: string | null,
    caller: string;
    total: number;
    cust: string;
    problem: string;
    solution: string;
    start: string;
    finish: string;
    callback: () => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface saveCustomerNotesRequest {
    cust: string;
    publicNote: string;
    privateNote: string;
    callback: () => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}

interface InitialLoadRequest {
    callback: (user: User, groups: Array<CustomerGroup>, users: Array<User>, customers: Array<Customer>, logs: Array<NewLog>) => void;
    errCallback: (response: ErrorResponse) => void;
    setRequestInProgress: (inProgress: boolean) => void;
}


interface ArrayDataResponse<T> {
    data: Array<T>
}

export interface ErrorResponse {
    success: boolean;
    data: string;
    loginUrl?: string;
}

interface BooleanResponse {
    success: boolean;
    data?: string;
}