import React from "react";
import CustomerGroup from "../../../js/classes/CustomerGroup.ts";
import MaintenanceCustomer from "../../../js/classes/CustomerMaintence.ts";
import CustomerCreationModal from "../../CustForms/Modals/customerCreation.tsx";
import CustomerGroupCreation from "../../CustForms/Modals/customerGroupCreation.tsx";
import CustomerGroupDeletionModal from "../../CustForms/Modals/customerGroupDeletion.tsx";
import CustomerGroupAssignModal from "../../CustForms/Modals/customerGroupAssign.tsx";
import CustomerGroupRemoveModal from "../../CustForms/Modals/CustomerGroupRemove.tsx";

interface CustomerActionButtonsProps {
  GroupCustomerSelected: MaintenanceCustomer[];
  CustomerCreationCallback: (success: boolean) => void;
  CustomerGroupCreationCallback: (success: boolean) => void;
  CustomerGroupDeletionCallback: (success: boolean) => void;
  groups: CustomerGroup[];
}

const CustomerActionButtons: React.FC<CustomerActionButtonsProps> = ({
  GroupCustomerSelected,
  CustomerCreationCallback = () => {},
  CustomerGroupCreationCallback = () => {},
  CustomerGroupDeletionCallback = () => {},
  groups = [],
}) => {
  return (
    <div className="d-flex justify-content-end mb-4">
      {!GroupCustomerSelected.length && (
        <>
          <CustomerCreationModal callback={CustomerCreationCallback} />
          <CustomerGroupCreation callback={CustomerGroupCreationCallback} hubGroups={groups} />
          <CustomerGroupDeletionModal callback={CustomerGroupDeletionCallback} hubGroups={groups}/>
        </>
      )}
      {GroupCustomerSelected.length > 0 && (
        <>
        {/* One to delete and one to add ?  */}
          <CustomerGroupAssignModal GroupCustomerSelected={GroupCustomerSelected} callback={CustomerGroupDeletionCallback} hubGroups={groups}/>
          <CustomerGroupRemoveModal GroupCustomerSelected={GroupCustomerSelected} callback={CustomerGroupDeletionCallback} hubGroups={groups}/>
        </>
      )}

    </div>
  );
};

export default CustomerActionButtons;
