import React from "react";
import { IAlertViewModel } from "../js/viewmodels/AlertViewModel";

export default function AlertModal({
	model
} : IAlertModal){

	if (model.message === ''){
		return (<></>)
	}

	function dismiss(){
		model.setMessage('');
	}

	const t = model.title !== '' ? model.title : 'Alert';

	return (
		<div className="modal show" tabIndex={-1}>
		  <div className="modal-dialog modal-dialog-centered">
		    <div className="modal-content">
		      <div className="modal-header">
		        <h5 className="modal-title">{ t }</h5>
		        <button type="button" className="btn-close" onClick={dismiss}></button>
		      </div>
		      <div className="modal-body">
		      	<p>{ model.message }</p>
		      </div>
		      <div className="modal-footer">
		        <button type="button" className="btn btn-danger" onClick={dismiss}>Close</button>
		      </div>
		    </div>
		  </div>
		</div>

	);

}

interface IAlertModal{
	model: IAlertViewModel;
}