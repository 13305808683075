import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Dropdown, DropdownButton } from 'react-bootstrap';
import ErrorDiv from "./common/ErrorModal.jsx";
import { Typeahead } from 'react-bootstrap-typeahead';
import { handleSubmitGroupSummariesReport, handleSubmitSupportLogSummariesReport, handleSubmitQuickCustomerListReport } from './customReports/reportSubmit.jsx';
import { HttpClientUtil } from './common/HttpClientUtil.ts';

const reports = [
  {
    id: "GroupSummariesReport",
    name: "Group Summaries Report",
    parameters: [
      { name: "periodDate", type: "date", label: "Period Ending" },
      { name: "customer", type: "GroupCustomer", label: "Group Customer" },
    ]
  },
  {
    id: "SupportLogsSummariesReport",
    name: "Support Log Summaries Report",
    parameters: [
      { name: "periodDate", type: "date", label: "Period Ending" },
      { name: "customer", type: "Customer", label: "Customer" },
    ]
  },
  {
    id: "QuickCustomerListReport",
    name: "Quick Customer List Report",
    parameters: [
      // { name: "periodDate", type: "date", label: "Period Ending" },
      // { name: "customer", type: "Customer", label: "Customer" },
    ]
  },
];

function ReportSelector({ onSelect }) {
  return (
    <DropdownButton id="dropdown-basic-button" title="Select Report" style={{ float: 'right' }}>
      {reports.map((report) => (
        <Dropdown.Item key={report.id} onClick={() => onSelect(report)}>
          {report.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

function ReportParameters({ report, formData, setFormData, setShowResponseDiv, setResponseMessage, setIsSuccess }) {
  const [downloadType, setDownloadType] = useState('PDF');
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const [groupCustomerData, setGroupCustomerData] = useState([]);

  useEffect(() => {
    const fetchCustomerData = async () => {
      setShowLoadingScreen(true);
      try {
        const GroupsCustomers = await HttpClientUtil.getRequest("/customer/getCustomerGroups");
        const Customers = await HttpClientUtil.getRequest("/customer/getCustomersList");
        
        if (GroupsCustomers.success && Customers.success) {
          setGroupCustomerData(GroupsCustomers.data);
          setCustomerData(Customers.data);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
      } finally {
        setShowLoadingScreen(false);
      }
    };

    fetchCustomerData();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCustomerSelection = (value) => {
    setFormData({ ...formData, customer: value });
  };

  const handleDownloadTypeSelect = (type) => {
    setDownloadType(type);
  };

  const displayError = (message) => {
    setShowResponseDiv(true);
    if (!message) {
      setResponseMessage("");
      setIsSuccess(true);
    } else {
      setResponseMessage(message);
      setIsSuccess(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    let id = report.id;
    let result = false;
    switch (id) {
      case "GroupSummariesReport":
        result = await handleSubmitGroupSummariesReport(formData, downloadType);
        break;
      case "SupportLogsSummariesReport":
        result = await handleSubmitSupportLogSummariesReport(formData, downloadType);
        break;
      case "QuickCustomerListReport":
        result = await handleSubmitQuickCustomerListReport(formData, downloadType);
        break;
      default:
        displayError("Undefined report");
        return;
    }
    if (!result.success) {
      displayError(result.message);
    } else {
      displayError(false);
    }
  };

  return showLoadingScreen ? (
    <div>Loading...</div>
  ) : (
    <Form onSubmit={handleFormSubmit}>
      <h2 className=" mb-5">{report.name}</h2>
      {report.parameters.map((param) => (
        <Form.Group as={Row} key={param.name} className="mb-3">
          <Form.Label column sm="2" className="text-sm-end">
            {param.label}
          </Form.Label>
          <Col sm="10">
            {param.type === 'GroupCustomer' ? (
              <Typeahead
                id={`${param.name}-typeahead`}
                labelKey="label"
                style={{ height: '44px' }}
                options={groupCustomerData.map((item) => ({
                  label: `${item.C_DIALCODE} - ${item.C_NAME}`,
                  value: item.C_DIALCODE,
                }))}
                onChange={(selected) => {
                  if (selected.length > 0) {
                    handleCustomerSelection(selected[0].value);
                  } else {
                    setFormData({ ...formData, customer: '' });
                  }
                }}
                placeholder="Select a customer..."
                clearButton
                className="mb-2 form-control"
              />
            ) : param.type === 'Customer' ? (
              <Typeahead
                id={`${param.name}-typeahead`}
                labelKey="label"
                style={{ height: '44px' }}
                options={customerData.map((item) => ({
                  label: `${item.C_DIALCODE} - ${item.C_NAME}`,
                  value: item.C_DIALCODE,
                }))}
                onChange={(selected) => {
                  if (selected.length > 0) {
                    handleCustomerSelection(selected[0].value);
                  } else {
                    setFormData({ ...formData, customer: '' });
                  }
                }}
                placeholder="Select a customer..."
                clearButton
                className="mb-2 form-control"
              />
            ) : (
              <Form.Control
                type={param.type}
                name={param.name}
                value={formData[param.name] || ''}
                onChange={handleInputChange}
                className="mb-2"
              />
            )}
          </Col>
        </Form.Group>
      ))}
      <Row className="justify-content-end">
        <Col sm="10">
          <DropdownButton
            title={`Download as ${downloadType}`}
            variant="secondary"
            className="mb-2"
            onSelect={handleDownloadTypeSelect}
          >
            <Dropdown.Item eventKey="PDF">PDF</Dropdown.Item>
            <Dropdown.Item eventKey="Excel">Excel</Dropdown.Item>
            <Dropdown.Item eventKey="CSV">CSV</Dropdown.Item>
          </DropdownButton>
          <Button type="submit" variant="primary">Run Report</Button>
        </Col>
      </Row>
    </Form>
  );
}

function App() {
  const [selectedReport, setSelectedReport] = useState(null);
  const [formData, setFormData] = useState({});
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Container>
      <div className="container ">
        <h1 className="mb-4 text-left">Report Builder</h1>
        <div className="card">
          <div className="card-body">
            <ErrorDiv
              success={isSuccess}
              errorMessage={responseMessage}
              isVisible={showErrorDiv}
              onClose={() => setShowResponseDiv(false)}
            />
            <ReportSelector onSelect={setSelectedReport} />
            {selectedReport && (
              <ReportParameters
                report={selectedReport}
                formData={formData}
                setFormData={setFormData}
                setShowResponseDiv={setShowResponseDiv}
                setResponseMessage={setResponseMessage}
                setIsSuccess={setIsSuccess}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default App;
