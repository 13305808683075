import React from "react";
import logo from './hub_circle.png';

/**
 * The NavBar component provides a consistent navigation bar component
 * for use across multiple Hub websites.
 * It expects the parent project to implement Bootstrap (CSS and JS)
 * and to include the css/style.css file from this repository.
 * 
 * @param title Text to display beside the Hub icon in the navbar
 * @param tabs Array of {tab:'Key', text:'Name', dropdown?: [{tab:'Key', text:'Name'}]} fields,
 * where the key is used to define which page the user is navigating to, and the text is
 * what to display in the actual tab. e.g. {tab:'companies', text:'Company List'}
 * @param selectedTab The currently selected tab.
 * This should be the first value (getter) of a useState text field which
 * is managed by the parent component. e.g. const [selectedTab, setTab] = useState('');
 * @param setTab Callback to set the newly-selected tab.
 * This should be the second value (setter) of a useState text field which
 * is managed by the parent component.
 */
export default function NavBar({
  title,
  tabs,
  selectedTab,
  setTab,
  isDarkMode = false
}: INavBar) {

  return (
    <nav className="navbar navbar-expand-md">
      <div className="container-fluid">
        <div className="navbar-brand">
          <img src={logo} alt="Logo"  className="circle d-inline-block align-text-top" style={{float:"left", marginRight: "3px"}}/>
          &nbsp;{title}
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {tabs.map(t => (
              <NavBarTab 
                key={t.tab}
                t={t}
                setTab={setTab}
                selectedTab={selectedTab}
                isDarkMode={isDarkMode}
              />
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

function NavBarTab({
  t,
  setTab,
  selectedTab,
  isDarkMode = false
}: INavBarTab){

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setTab(t.tab);
  };

  const aClass = selectedTab === t.tab ? "nav-link active" : "nav-link";

  if (t.dropdown && t.dropdown.length > 0) {
    return (
      <li className="nav-item dropdown">
        <a className={`${aClass} dropdown-toggle`} href="#!" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {t.text}
        </a>
        <ul
        data-bs-theme={isDarkMode ? "dark" : "light"}
        className="dropdown-menu" aria-labelledby="navbarDropdown">
          {t.dropdown.map(subTab => (
            <li key={subTab.tab}>
              <a className="dropdown-item" href="#!" onClick={(e) => { e.preventDefault(); setTab(subTab.tab); }}>
                {subTab.text}
              </a>
            </li>
          ))}
        </ul>
      </li>
    );
  }

  return (
    <li className="nav-item">
      <a className={aClass} onClick={handleClick} href="#!">
        {t.text}
      </a>
    </li>
  );
}

interface INavBar{
  title: string;
  tabs: ITab[];
  selectedTab: string;
  setTab: (tab: string) => void;
  isDarkMode: boolean;
}

interface INavBarTab{
  t: ITab;
  setTab: (tab: string) => void;
  selectedTab: string;
  isDarkMode: boolean;
}

export interface ITab{
  tab: string;
  text: string;
  dropdown?: ITab[];
}
