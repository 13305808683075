import React, { ReactElement, ChangeEvent, KeyboardEvent, useRef, useEffect } from "react";

/**
 * An input field with bootstrap styling for use in a form.
 * Supplies an optional label and works with different input types.
 *
 * @param id Unique identifier for this input field
 * @param name Unique name for this input field
 * @param label If set, a <label> element is used to display the text in @label
 * @param type Input type. Defaults to text
 * @param rowCount Number type. Defaults to 1 only used for textarea inputs
 * @param extraClass Extending Style Type. Should be a string that adds an additional class to the input field
 * @param value useState getter managed by parent
 * @param setValue useState setter managed by parent, or undefined if readonly={true}
 * @param autoFocus If true, focus this input field when the component loads
 * @param autoSelect If true, focus and select this input field when the component loads
 * @param onEnter Callback to run when the user presses Enter within this field
 * @param onBlur Callback to run when the field loses focus
 * @param maxlen If set, this value defines the maximum length of the field's value
 * @param readonly If true, the input field is readonly. setValue should be undefined
 */
export default function InputField({
  id = "",
  name = "",
  label = "",
  type = "text",
  extraClass = "",
  rowCount = 1,
  value,
  setValue,
  autoFocus = false,
  autoSelect = false,
  onEnter = () => {},
  onBlur = () => {},
  maxlen,
  readonly = false,
}: IInputField) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const ref = type === "textarea" ? textareaRef.current : inputRef.current;
    if (autoFocus || autoSelect) ref?.focus();
    if (autoSelect) ref?.select();
  }, [autoFocus, autoSelect, type]);

  const handleChange =
    typeof setValue !== "undefined"
      ? (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setValue(e.target.value)
      : undefined;

  const onKeyPress = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      onEnter();
    }
  };
  

  const blur = () => onBlur(value);

  const className = `form-control ${extraClass}`;
  const isTextarea = type === "textarea";

  const span: ReactElement = name ? (
    <span className="input-group-text">{name}</span>
  ) : label ? (
    <label className="small">{label}</label>
  ) : (
    <></>
  );

  const divClass = name.length > 0 ? "input-group mb-3" : "mb-3";
  return (
    <div className={divClass}>
      {span}
      {isTextarea ? (
        <textarea
          id={id}
          className={className}
		  rows={rowCount}
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyPress}
          ref={textareaRef}
          onBlur={blur}
          maxLength={maxlen}
          readOnly={readonly}
        />
      ) : (
        <input
          id={id}
          type={type}
          className={className}
          value={value}
          onChange={handleChange}
          onKeyDown={onKeyPress}
          ref={inputRef}
          onBlur={blur}
          maxLength={maxlen}
          readOnly={readonly}
        />
      )}
    </div>
  );
}

interface IInputField {
  id?: string;
  name?: string;
  label?: string;
  type?: string;
  rowCount?: number; // For textarea only
  extraClass?: string;
  value: string;
  setValue?: (newValue: string) => void;
  autoFocus?: boolean;
  autoSelect?: boolean;
  onEnter?: () => void;
  onBlur?: (value: string) => void;
  maxlen?: number;
  readonly?: boolean;
}
