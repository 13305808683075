import {  useState } from 'react';

export default function AlertViewModel(): IAlertViewModel {

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [acceptCallback, setAcceptCallbackState] = useState<() => void>(() => {});

  const show = ({ msg, title = '', callback }: IAlertViewMessage) => {
    setTitle(title);
    setMessage(msg);
    if (callback) {
      setAcceptCallbackState(() => callback); 
    }
  };


  

  return {
  	title, setTitle,
  	message, setMessage,
    show,
    acceptCallback
  };

}

export interface IAlertViewModel{
  title: string;
  setTitle: (value: string) => void;
  message: string;
  setMessage: (value: string) => void;
  show: (args: IAlertViewMessage) => void;
  acceptCallback
}

interface IAlertViewMessage{
  msg: string;
  title?: string;
  callback?: () => void;
}
