import { useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import User from "../classes/User.ts";

export default function UserSettingsViewModel(): IUserSettingsViewModel {
    const [user, setUser] = useState<User | null>(null);

    // Manage userSettings with useLocalStorage
    const [userSettings, setUserSettings] = useLocalStorage<IUserSettings>("userSettings", {
        defaultLogEditFormat: 'h:mm', // Default value if no saved settings
        titleChange: false,
        darkMode: false,
        showEmojis: false,
        showActiveLogGlow: true,
    });

    const [callback, setCallback] = useState<((newSettings: IUserSettings) => void) | null>(null);

    const show = (user: User) => {
        setUser(user);
    };

    const updateUserSettings = (settings: Partial<IUserSettings>) => {
        setUserSettings((prevSettings) => ({
            ...prevSettings,
            ...settings,
        }));
    };

    const hide = () => {
        setUser(null);
    };

    const submit = () => {
        if (callback) {
            // Callback at the app level
            callback(userSettings);
        }
        hide();
    };

    // Function to set the callback
    const registerCallback = (cb: (newSettings: IUserSettings) => void) => {
        setCallback(() => cb);
    };

    return {
        user,
        userSettings,
        show,
        hide,
        submit,
        updateUserSettings,
        registerCallback,
    };
}

export interface IUserSettingsViewModel {
    user: User | null;
    userSettings: IUserSettings;
    show: (user: User) => void;
    hide: () => void;
    submit: () => void;
    updateUserSettings: (settings: Partial<IUserSettings>) => void;
    registerCallback: (cb: (newSettings: IUserSettings) => void) => void;
}

export interface IUserSettings {
    defaultLogEditFormat: string;
    titleChange: boolean;
    showEmojis: boolean;
    darkMode: boolean;
    showActiveLogGlow: boolean;
    // showManagementTab: boolean;
}
