import React from 'react';

const ErrorDiv = ({ success, errorMessage, isVisible, onClose = () => {  } }) => {
  const alertClass = success ? 'alert-success' : 'alert-danger';

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`alert ${alertClass} alert-dismissible fade show`} role="alert">
      {success ? 'Success!' : errorMessage}
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={onClose}></button>
    </div>
  );
};

export default ErrorDiv;
