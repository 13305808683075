import React, { useEffect } from 'react';
import LoadingScreenViewModel, { ILoadingScreenViewModel } from '../js/viewmodels/LoadingScreenViewModel.ts';

export default function LoadingScreen(args : ILoadingScreenViewModel) {

	const model = LoadingScreenViewModel();

	useEffect(() => {
		model.loadSession(args);
	}, []);

	return (
		<h1>{ model.message }</h1>
	)
}
