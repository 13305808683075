import {useEffect, useState, Dispatch, SetStateAction } from 'react';
import { HttpClientUtil } from "../../components/common/HttpClientUtil.ts";
import CustomerGroup from "../../js/classes/CustomerGroup.ts";
import maintenanceCustomer from "../../js/classes/CustomerMaintence.ts";

export default function CustomerMaintenanceViewModel(): ICustomerMaintenanceViewModel {
  const [customerData, setCustomerData] = useState<maintenanceCustomer[]>([]);
  const [customer, setCustomer] = useState<string>("");
  const [groups, setGroups] = useState<CustomerGroup[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<maintenanceCustomer[]>([]);
  const [activeCustomerDetails, setActiveCustomerDetails] = useState<Partial<maintenanceCustomer>>({});
  const [showTable, setShowTable] = useState<boolean>(true);
  const [filteredData, setFilteredData] = useState<maintenanceCustomer[]>([]);
  const [showLoading, setShowLoadingScreen] = useState<boolean>(true);
  const [activeTabKey, setActiveTabKey] = useState<string>("basicInfo");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [showErrorDiv, setShowResponseDiv] = useState<boolean>(false);
  const [actionResponse, setResponseAction] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [savedCallback, setCallback] = useState<(groups: CustomerGroup[]) => void>(() => (groups: CustomerGroup[]) => {});

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await HttpClientUtil.getRequest("/customer/getCustomersList");
        
        if (response.success) {
          const data = response.data;
          const customerData = data.map(customer => new maintenanceCustomer(customer));

          
          setCustomerData(customerData);
          setFilteredData(customerData);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
      }
    };
    fetchCustomerData();
    setShowLoadingScreen(false);
  }, [refresh]);


  const setGroupsGlobal = (groups: CustomerGroup[], originalCallback?: (updatedGroups: CustomerGroup[]) => void) => {

    setGroups(groups);

    //first call with callback save the callback as a state
    if (originalCallback){
      setCallback(() => originalCallback);
      originalCallback(groups);
    }
    //used the stated callback
    if (savedCallback) {
      savedCallback(groups);
    }
  }

  const handleCustomerGroupSelection = (selectedCustomers: maintenanceCustomer[]) => {
    setSelectedCustomers(selectedCustomers);
  };

  const handleCustomerSelection = async (dialCode: string) => {
    setCustomer(dialCode);
    const details = customerData.find((cust) => cust.C_DIALCODE === dialCode);
    setActiveCustomerDetails(details || {});
    setShowTable(false);

    try {
      const response = await HttpClientUtil.getRequest(`/customer/getCustomerInfo?id=${dialCode}`);
      if (!response.success) {
        throw new Error("Network response was not ok");
      }
      const data: maintenanceCustomer = await response.data;

      setActiveCustomerDetails(data || {});
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const handleSearch = (query: string) => {
    if (!query) {
      setFilteredData(customerData);
      setShowTable(true);
    } else {
      const lowerCaseQuery = query.toLowerCase();
      setFilteredData(
        customerData.filter(
          (customer) =>
            customer.C_NAME.toLowerCase().includes(lowerCaseQuery) ||
            customer.C_DIALCODE.toLowerCase().includes(lowerCaseQuery)
        )
      );
    }
  };

  const handleRefresh = (bool: boolean) => {
    if (!bool) return;
    setShowResponseDiv(true);
    setResponseAction(true);
    setCustomer("");
    setActiveCustomerDetails({});
    setShowTable(true);
    setRefresh((prev) => !prev);
  };

  return {
    customerData,
    customer,
    setCustomer,
    selectedCustomers,
    setSelectedCustomers,
    activeCustomerDetails,
    setActiveCustomerDetails,
    showTable,
    setShowTable,
    filteredData,
    setFilteredData,
    showLoading,
    setShowLoadingScreen,
    activeTabKey,
    setActiveTabKey,
    refresh,
    setRefresh,
    showErrorDiv,
    setShowResponseDiv,
    actionResponse,
    setResponseAction,
    responseMessage,
    setResponseMessage,
    handleCustomerSelection,
    handleSearch,
    handleCustomerGroupSelection,
    handleRefresh,
    groups,
    setGroupsGlobal,

  };
}

export interface ICustomerMaintenanceViewModel {
    customerData: maintenanceCustomer[];
    customer: string;
    setCustomer: Dispatch<SetStateAction<string>>;  
    selectedCustomers: maintenanceCustomer[];
    setSelectedCustomers: Dispatch<SetStateAction<maintenanceCustomer[]>>;  
    activeCustomerDetails: Partial<maintenanceCustomer>;
    setActiveCustomerDetails: Dispatch<SetStateAction<Partial<maintenanceCustomer>>>;  
    showTable: boolean;
    setShowTable: Dispatch<SetStateAction<boolean>>;  
    filteredData: maintenanceCustomer[];
    setFilteredData: Dispatch<SetStateAction<maintenanceCustomer[]>>;  
    showLoading: boolean;
    setShowLoadingScreen: Dispatch<SetStateAction<boolean>>;  
    activeTabKey: string;
    setActiveTabKey: Dispatch<SetStateAction<string>>;  
    refresh: boolean;
    setRefresh: Dispatch<SetStateAction<boolean>>;  
    showErrorDiv: boolean;
    setShowResponseDiv: Dispatch<SetStateAction<boolean>>;  
    actionResponse: boolean;
    setResponseAction: Dispatch<SetStateAction<boolean>>;  
    responseMessage: string;
    setResponseMessage: Dispatch<SetStateAction<string>>;  
    handleCustomerSelection: (dialCode: string) => Promise<void>;
    handleSearch: (query: string) => void;
    handleCustomerGroupSelection: (selectedCustomers: maintenanceCustomer[]) => void;
    handleRefresh: (bool: boolean) => void;
    groups: CustomerGroup[];
    setGroupsGlobal: (groups: CustomerGroup[], originalCallback?: (updatedGroups: CustomerGroup[]) => void) => void;
  }