import { HttpClientUtil } from '../common/HttpClientUtil.ts';
export const handleSubmitGroupSummariesReport = async (formData, type) => {
    if (!formData.periodDate) {
        return { success: false, message: "Please pick a valid date"
        };
    }
    let t = type.toLowerCase();
    
    const result = await HttpClientUtil.postRequest('/run/report/groupsummary', {
        customer: formData.customer,
        date: formData.periodDate,
        format: t
    }, true);
   return result;
};

export const handleSubmitSupportLogSummariesReport = async (formData, type) => {
    if (!formData.periodDate) {
        return { success: false, message: "Please pick a valid date"
        };
    }
    let t = type.toLowerCase();
    
    const result = await HttpClientUtil.postRequest('/run/report/supportlogs', {
        customerCode: formData.customer,
        periodEnd: formData.periodDate,
        format: t
    }, true);
   return result;
}


export const handleSubmitQuickCustomerListReport = async (formData, type) => {
    
    let t = type.toLowerCase();
    
    const result = await HttpClientUtil.postRequest('/run/report/quickcustomerlist', {
        format: t
    }, true);
   return result;
}