import React from "react";
import Autocomplete from "./Autocomplete.tsx";
import InputField from "../common/components/InputField.tsx";
import SubmitButton from "../common/components/SubmitButton.tsx";
import { ICustomerScreenViewModel } from "../js/viewmodels/CustomerScreenViewModel";
import  { ICustomer } from "../js/classes/Customer.ts";
import AlertViewModel from "../common/js/viewmodels/AlertViewModel.ts";
import AlertModal from "../common/components/AlertModal.tsx";

export default function CustomerScreen({
	model
}: ICustomerScreen) {

	const alertModel = AlertViewModel();

	const error = (msg: string) => {
		
		
		alertModel.show({
			title: 'Error',
			msg: msg
		});
	};

	return (
		<>
			<div className="row">
				<Autocomplete
					name="Customer"
					id="notesCustomer"
					datalist={model.customers}
					setValue={model.setCustomerHandle}
					labelKey="name"
					clearOnComplete={true}
					/>
			</div>
			<CustomerRow model={model} error={error}/>
			<CustomerGroupRow model={model} error={error}/>
			<AlertModal model={alertModel}/>
        </>
	)
}

function CustomerRow({
	model, error
}: ICustomerRow){

	if (model.customer === null){
		return (<></>);
	}

	const save = () => {
		model.saveCustomerSettings(error)
	};

	
	return (
		<div className="row card">
			<div className="card-header">
				<h5>Customer - { model.customer.name }</h5>
			</div>
			<div className="card-body">
				<CustomerHours
					customer={model.customer}
					/>
				<InputField
					id="publicGroupNotes"
					value={model.publicNote}
					setValue={model.setPublicNote}
					name="Public notes"
					/>
				<InputField
					id="privateGroupNotes"
					value={model.privateNote}
					setValue={model.setPrivateNote}
					name="My private notes"
					/>
				{/* <Checkbox
					id="prbRequired"
					value={model.prbRequired}
					setValue={model.setPrbRequired}
					label="PRB number required"
					/> */}
			</div>
			<div className="card-footer align-right">
				<SubmitButton
					text="Save"
					onClick={save}
					requestInProgress={model.savingCustomerInProgress}
					loadingText="Saving..."
					/>
			</div>
		</div>
	);
}

function CustomerHours({
	customer
}: ICustomerModel){

	const text = "*If a customer asks how many support hours they have left, do not tell them. Put them through to Brett instead.";

	if (customer.customerHoursMins > 0){
		return (
			<div className="col s12">
				<p>
					{ customer.name } has { customer.customerHoursText } support time remaining this period.<br/>
					{text}
				</p>
			</div>
		);
	}else{
		return (
			<div className="col s12">
				<p className="red-text">
					{ customer.name } is { customer.customerHoursText } over their support time for this period.<br/>
					{text}
				</p>
			</div>
		);
	}

}

function CustomerGroupRow({
	model, error
}: ICustomerRow){

	if (model.customer === null || model.customer.group === null || model.customer.group.length === 0){
		return (<></>);
	}

	const save = () => {
		model.saveGroupSettings(error)
	};

	return (
		<div className="row card card-padded">
			<div className="card-header">
				<h5>Customer group - { model.customer.group }</h5>
			</div>
			<div className="card-body">
				{/* <Checkbox
					id="groupPrbRequired"
					value={model.prbGroupRequired}
					setValue={model.setPrbGroupRequired}
					label="PRB number required"
					/> */}
				<InputField
					id="publicGroupNotes"
					value={model.publicGroupNote}
					setValue={model.setPublicGroupNote}
					name="Public notes"
					/>
				<InputField
					id="privateGroupNotes"
					value={model.privateGroupNote}
					setValue={model.setPrivateGroupNote}
					name="My private notes"
					/>
			</div>
			<div className="card-footer align-right">
				<SubmitButton
					text="Save"
					onClick={save}
					requestInProgress={model.savingGroupInProgress}
					loadingText="Saving..."
					/>
			</div>
		</div>
	);
}

interface ICustomerScreen{
	model: ICustomerScreenViewModel;
}

interface ICustomerRow{
	model: ICustomerScreenViewModel;
	error: (msg: string) => void;
}

interface ICustomerModel{
	customer: ICustomer
}