import React from 'react';
import { useLocalStorage } from "usehooks-ts";
import { IUserSettings } from "../js/viewmodels/UserSettingsViewModel.ts";
import { Play, Pause, Pencil, InfoCircleFill, TrashFill, CheckLg } from 'react-bootstrap-icons';
import InputField from '../common/components/InputField.tsx';
import NewLog from '../js/classes/NewLog';
import { ITodaysLogsViewModel } from '../js/viewmodels/TodaysLogsViewModel.ts';
import { IActiveLogModalViewModel } from '../js/viewmodels/ActiveLogModalViewModel.ts';
import IconButton from './IconButton.tsx';
import { IAlertViewModel } from '../common/js/viewmodels/AlertViewModel.ts';
import { IRecentLogViewModel } from '../js/viewmodels/RecentLogViewModel.ts';

export default function LogCard(args: ILogAndBothModels){

    const { log, model, editModel, alertMessageModel, recentModel, error } = args;
    
    if (model.activeLog.id === log.id){
        return (<ExpandedLogCard log={log} model={model} editModel={editModel} alertMessageModel={alertMessageModel} recentModel={recentModel} error={error}/>);
    }else{
        return (<CollapsedLogCard log={log} model={model} editModel={editModel} alertMessageModel={alertMessageModel} recentModel={recentModel} error={error}/>);
    }

}

function HeaderPlayIcon(args: ILogAndBothModels){

    const { log, model } = args;

    const isCompleted = log.completed; 
    const runningStyle = { };

    if (isCompleted)
        return <CheckLg className="right icon-glow-green"/>;

    if (log.id === model.runningLog.id)
        return (<Play className="right icon-glow-blue" style={runningStyle}/>);
    else
        return (<Pause className="right icon-glow-red"/>);

}

function Header(args: ILogAndBothModels) {
    const { log, model, editModel, alertMessageModel, recentModel, error } = args;

    


    const [userSettings] = useLocalStorage <Partial< IUserSettings>>("userSettings", {
        showActiveLogGlow: true,
    });
    const showActiveLogGlow = userSettings.showActiveLogGlow;


    const collapseOrExpand = () => {
        const logToShow = model.activeLog.id === log.id ? null : log;
        model.activeLog.setLog(logToShow);
    };


    const time: string = (model.runningLog.id === log.id) ? model.runningLog.display : log.displayTime();
    
    let activelog = model.runningLog.id === log.id;

    let openLogViewCheck = model.activeLog.id === log.id;
    let className = (activelog && showActiveLogGlow && !openLogViewCheck ) 
        ? "card-header active" : "card-header";
return (
        <div className={className} onClick={collapseOrExpand}>
            <span>{ log.customer.code } { time } { log.caller } - { log.problem }</span>
            <HeaderPlayIcon log={log} model={model} editModel={editModel} alertMessageModel={alertMessageModel} recentModel={recentModel}  error={error}/>
        </div>
    );

}

function CollapsedLogCard(args: ILogAndBothModels){

    const { log, model, editModel, alertMessageModel, recentModel, error } = args;

    return (
        <div className="card ps-0 pe-0">
            <Header log={log} model={model} editModel={editModel} alertMessageModel={alertMessageModel} recentModel={recentModel} error={error}/>
        </div>
    );

}

function PublicNotes(args: ILog){

    const { log } = args;

    if (log.customer.publicNote.length === 0){
        return (<></>);
    }

    var style = {color:'darkgreen'};

    return (
        <div className="col mb-1">
            <p style={style}>{ log.customer.publicNote }</p>
        </div>
    );

}

function PrivateNotes(args: ILog){

    const { log } = args;

    if (log.customer.privateNote.length === 0){
        return (<></>);
    }

    var style = {color:'dodgerblue'};

    return (
        <div className="col mb-1">
            <p style={style}>{ log.customer.privateNote }</p>
        </div>
    );

}

function PrbPrompt(args: ILog){

    const { log } = args;

    if (log.customer.isPrb())
        return (
            <div className="col mb-1">
                <p className="p-10 red-text">*PRB number required</p>
            </div>
        );
    else
        return (<></>);

}

function OnHoldWarning(args: ILog){

    const { log } = args;

    if (log.customer.onHold)
        return (
            <div className="col mb-1">
                <p className="p-10 red-text">*Warning Customer is ONHOLD make sure your logging is correct</p>
            </div>
        );
    else
        return (<></>);

}

function SupportTime(args: ILog){

    const { log } = args;

    if (log.customer === null){
        return (<></>);
    }

    if (parseFloat(log.customer.customerHoursText) > 0){
        return (
            <div className="col s12">
                <p>
                    <b>{ log.customer.name }</b> has <span style={{ fontWeight: 'bold', color: 'green' }}>{ log.customer.customerHoursText }</span> support time remaining this period.<br/>
                    *If a customer asks how many support hours they have left, do not tell them. Put them through to Brett instead.
                </p>
            </div>
        );
    }else{
        return (
            <div className="col s12">
                <p className="">
                    <b>{log.customer.name}</b> is 
                    <span 
                        style={{ fontWeight: 'bold', color: 'red' }}>
                    {' '}{log.customer.customerHoursText}{' '}
                    </span> 
                    over their support time for this period.<br/>
                    *If a customer asks how many support hours they have left, do not tell them. Put them through to Brett instead.<br/>
                    Note that this figure might not be accurate if the logs haven't been rolled yet.
                </p>
            </div>
        );
    }

}





function Footer(args: ILogAndBothModels){

    const { log, model, editModel, alertMessageModel, recentModel, error } = args;

    // backend should handle this
    // if (model.requestInProgress){
    //     return (<></>);
    // }

    const isRunning = model.runningLog.id === log.id;
    // const showPaused = !model.requestInProgress && !isRunning;
    // const showRunning = !model.requestInProgress && isRunning;
    const showPaused = !isRunning;
    const showRunning = isRunning;
    // const showAlways = !model.requestInProgress;
    const showAlways = true;

    const isComplete = log.completed;

    const pause = () => model.pause(error, () => {});
    const resume = () => model.resume(log, error);
    const edit = () => {
        
        // save the log before editing "force it"
        let logsCurrentState = model.activeLog.save();
        editModel.show(logsCurrentState);
    }
    const recent = () => {
        recentModel.show(log.customer);
        recentModel.fetchCustomerLogs(log.customer, error );
    }
    
    // model.recent(log.customer);
    const promptDelete = () => {
        const deleteFunction = () => {
            model.removeLog(error);
        }

        alertMessageModel.show({
            title: "Delete Log",
            msg: "Are you sure you want to delete this log entry?",
            callback: deleteFunction
        });

    };

    const submitLog = () => {
        const submit = () => {        
            model.submitLog(log, error, () => {
                // Remove the log from the active state
                // i.e., collapse the log.
                // set the next log as active
                let index = model.logs.indexOf(log);
                let nextLog = model.logs[index + 1] || null;
                
                model.activeLog.setLog(nextLog);
                // model.activeLog.setLog();
            });
        };
    
        if (log.customer.isPrb() && !log.prb) {
            alertMessageModel.show({
                title: "Submit",
                msg: "Are you sure you want to submit this Log without a PRB Number?",
                callback: submit, // Directly pass the submit function
            });
            return;
        }
    
        submit();
    };

    return (
        <div className="card-footer">
            <div className="row">
                
            {!isComplete && (
                <>
                <div className="col">
                    
                    <IconButton
                        btnStyle="danger"
                        text="Pause"
                        onClick={pause}
                        icon={<Pause/>}
                        show={showRunning}
                        />

                    <IconButton
                        btnStyle="primary"
                        text="Resume"
                        onClick={resume}
                        icon={<Play/>}
                        show={showPaused}
                        />

                    <IconButton
                        btnStyle="warning"
                        text="Edit"
                        onClick={edit}
                        icon={<Pencil/>}
                        show={showPaused}
                        />

                    <IconButton
                        btnStyle="info"
                        text="Recent"
                        onClick={recent}
                        icon={<InfoCircleFill/>}
                        show={showAlways}
                        />

                </div>

                <div className="col align-right">
                    <IconButton
                        btnStyle="success"
                        text="Submit"
                        onClick={submitLog}
                        icon={<CheckLg/>}
                        show={!isRunning}
                        inProgress={model.requestInProgress}
                        inProgressText="Submitting..."
                        />

                    <IconButton
                        btnStyle="danger"
                        text="Delete"
                        onClick={promptDelete}
                        icon={<TrashFill/>}
                        show={showPaused}/>

                </div>
            
                </>
            )}
            </div>
        </div>
    );
}

function ExpandedLogCard(args: ILogAndBothModels){

    const { log, model, editModel, alertMessageModel, recentModel, error } = args;

    return (
        <div className="card ps-0 pe-0">
            <Header log={log} model={model} editModel={editModel} alertMessageModel={alertMessageModel} recentModel={recentModel} error={error}/>
            <Body log={log} model={model} editModel={editModel} alertMessageModel={alertMessageModel} recentModel={recentModel} error={error}/>
			<Footer log={log} model={model} editModel={editModel} alertMessageModel={alertMessageModel} recentModel={recentModel} error={error}/>
		</div>
    );

}

function Body(args: ILogAndBothModels){

    const { log, model, error } = args;

    const change = () => {
        model.activeLog.change((updatedLog: NewLog) => {
            model.save(updatedLog, error, () => {
                // model.activeLog.setLog(updatedLog);
                //success
            });
        });
    };
    // console.log(model);
    // let activelog = model.runningLog.id === log.id;
    
    let isPrb = log.customer.isPrb();
    return (
        <div className="card-body">

            <OnHoldWarning log={log}/>
            <PrbPrompt log={log}/>

            <PublicNotes log={log}/>

            <PrivateNotes log={log}/>
            
            
            {isPrb && (
                <InputField
                    name="Prb:"
                    value={model.activeLog.prb ?? ''}
                    setValue={model.activeLog.setPrb}
                    onBlur={change}
                    maxlen={100}
                    autoFocus={true}
                    readonly={model.activeLog.completed}
                    extraClass={!model.activeLog.prb ? 'glow-red' : ''}
                />
            )}
        {/* if customer isn't PRB auto focus the caller */}
            <InputField
                name="Caller:"
                value={model.activeLog.caller}
                setValue={model.activeLog.setCaller}
                onBlur={change}
                maxlen={100}
                autoFocus={!isPrb}
                readonly={model.activeLog.completed}
            />

            <InputField
                name="Problem:"
                type='textarea'
                value={model.activeLog.problem}
                setValue={model.activeLog.setProblem}
                onBlur={change}
                maxlen={302}
                readonly={model.activeLog.completed}
            />

            <InputField
                name="Solution:"
                type='textarea'
                value={model.activeLog.solution}
                setValue={model.activeLog.setSolution}
                onBlur={change}
                maxlen={302}
                readonly={model.activeLog.completed}
            />

            <SupportTime log={log}/>

        </div>
    );
}

interface ILog{
    log: NewLog;
}

interface ILogAndBothModels{
    log: NewLog;
    model: ITodaysLogsViewModel;
    editModel: IActiveLogModalViewModel;
    alertMessageModel: IAlertViewModel;
    recentModel: IRecentLogViewModel
    error: (msg: string) => void;
}

