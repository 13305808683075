import { DateTime, Duration } from "luxon";
import Customer from "./Customer";


export default class NewLog {
    readonly id: number; 
    start: DateTime;
    finish: DateTime; 
    total: Duration;
    customer: Customer;
    prb: string | null; 
    caller: string;
    problem: string;
    solution: string;
    completed: boolean;

    constructor(cust: Customer, val?: INewLog) {
        this.id = val?.l_id || 0; 
        this.total = Duration.fromObject({
            years: 0,
            quarters: 0,
            months: 0,
            weeks: 0,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: val?.l_total || 0,
            milliseconds: 0
        });

        if (val && val.l_start) {
            const parsedStart = DateTime.fromFormat(val.l_start, 'yyyy-MM-dd HH:mm:ss');
            if (parsedStart.isValid) {
                this.start = parsedStart;
            } else {
                console.error("Invalid date format for start:", val.l_start);
                this.start = DateTime.now();
            }
        } else {
            this.start = DateTime.now();
        }

        if (val && val.l_finish) {
            const parsedFinish = DateTime.fromFormat(val.l_finish, 'yyyy-MM-dd HH:mm:ss');
            if (parsedFinish.isValid) {
                this.finish = parsedFinish;
            } else {
                console.error("Invalid date format for finish:", val.l_finish);
                this.finish = DateTime.now();
            }
        } else {
            this.finish = DateTime.now();
        }

        this.customer = cust;
        
        if (cust.isPrb())
            this.prb = val?.l_prb || null;
        else
            this.prb = null;

        this.caller = val?.l_caller || '';
        this.problem = val?.l_problem || '';
        this.solution = val?.l_solution || '';
        this.completed = val?.completed || false;
    }

displayTime(): string {
    return this.total.toFormat('h:mm:ss');
}

markAsCompleted(): void {
    this.completed = true;
}

getSeconds(): number {
    return this.total.as('seconds');
}

}

export interface INewLog{
l_id: number;
l_start: string;
// optional I guess ? as some logs aren't finished.
l_finish: string;
l_total: number;
l_cust: string;
l_prb?: string | null;
l_caller: string;
l_problem: string;
l_solution: string;
completed?: boolean;

}
