import React from "react";

/**
 * Component for a button with a spinner (loading image) inside of it.
 * The button is disabled and cannot be clicked.
 * 
 * This component should be used in cases where a user has clicked a button and is waiting for a response.
 * eg. When they've submitted a form.
 * This component should then be shown while the request is in progress.
 * 
 * Example:
 * const submitButton = requestInProgress ? (<LoadingButton/>) : (<button>Submit</button>);
 * 
 * @param style Bootstrap button style, without the btn- prefix. eg. "primary" or "success"
 * @param text Text to display inside of the loading button next to the spinner
 */
export default function LoadingButton({
    style="primary",
    text="Loading..."
} : ILoadingButton){

    const classes = "btn btn-"+style;

    return (
        <button className={classes} type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            &nbsp;{text}
        </button>
    )
    
}

interface ILoadingButton{
    style?: string;
    text?: string;
}