import { useState } from "react";
import { DateTime, Duration } from "luxon";
import Customer from "../classes/Customer.ts";
import NewLog from "../classes/NewLog.ts";

export default function ActiveLogViewModel(): IActiveLogViewModel{

    
    const defaultDuration = Duration.fromObject({
        years:0,
        quarters:0,
        months:0,
        weeks:0,
        days:0,
        hours:0,
        minutes:0,
        seconds:0,
        milliseconds:0
    });

    const [id, setId] = useState<number>(-1);
    const [start, setStart] = useState<DateTime>(DateTime.now());
    const [total, setTotal] = useState<Duration>(defaultDuration);
    const [customer, setCustomer] = useState<Customer>(Customer.createEmpty());
    const [prb, setPrb] = useState<string | null>(null);
    const [caller, setCaller] = useState<string>("");
    const [problem, setProblem] = useState<string>("");
    const [solution, setSolution] = useState<string>("");
    const [lastSavedPrb, setLastSavedPrb] = useState<string | null> (null);
    const [lastSavedCaller, setLastSavedCaller] = useState<string>("");
    const [lastSavedProblem, setLastSavedProblem] = useState<string>("");
    const [lastSavedSolution, setLastSavedSolution] = useState<string>("");
    const [completed, setCompleted] = useState<boolean>(false);

    const setLog = (log: NewLog | null) => {
        if (log === null){
            setId(-1);
        }else{
            setId(log.id);
            setStart(log.start);
            setTotal(log.total);
            setCustomer(log.customer);
            
            setPrb(log.prb);
            setCaller(log.caller);
            setProblem(log.problem);
            setSolution(log.solution);

            
            setLastSavedPrb(log.prb);
            setLastSavedCaller(log.caller);
            setLastSavedProblem(log.problem);
            setLastSavedSolution(log.solution);
            
            setCompleted(log.completed);
        }
    };

    const save: () => NewLog = () => {
        // might as well do this one 
        
        const finish = DateTime.now();
        setStart(finish.minus(total));


        return new NewLog(customer, {
            l_id: id,
            l_start: start.toFormat('yyyy-MM-dd HH:mm:ss'),
            l_finish: finish.toFormat('yyyy-MM-dd HH:mm:ss'),
            l_total: total.as('seconds'),
            l_cust: customer.code,
            l_prb: prb,
            l_caller: caller,
            l_problem: problem,
            l_solution: solution,
        });
    };

    /*
    should call save after
    */
    const change = (
        callback: (log: NewLog) => void
    ) => {
        if (
            prb !== lastSavedPrb ||
            caller !== lastSavedCaller ||
            problem !== lastSavedProblem ||
            solution !== lastSavedSolution
        ){
            

            setLastSavedPrb(prb);
            setLastSavedCaller(caller);
            setLastSavedProblem(problem);
            setLastSavedSolution(solution);

			var newLog = save();
            callback(newLog);
		}
    };

    const deleteLog = () => {

        const trimmedSolution = `(DELETED) ${solution}`.substring(0, 255)
		
        const log = save();
        log.solution = trimmedSolution;

		return log;

	};

	const displayTime = () => {
		return total.toFormat('h:mm:ss');
	}

    return {
        id,
        prb, setPrb,
        caller, setCaller,
        problem, setProblem,
        solution, setSolution,
        customer,
        setLog,
        change,
        save,
        deleteLog,
        setTotal,
        displayTime,
        completed,
        //
    }

}

export interface IActiveLogViewModel{
    id: number;
    prb: string | null;
    setPrb: (value: string | null ) => void;
    caller: string;
    setCaller: (value: string) => void;
    problem: string;
    setProblem: (value: string) => void;
    solution: string;
    setSolution: (value: string) => void;
    setLog: (log: NewLog | null) => void;
    change: (callback: (log: NewLog) => void) => void;
    save: () => NewLog;
    deleteLog: () => NewLog;
    setTotal: (value: Duration) => void;
    customer: Customer;
    completed: boolean;
    displayTime: () => string;
}