import React, { useState } from "react";
import { ListGroup } from "react-bootstrap";
import ErrorDiv from "../../common/ErrorModal";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import IconButton from "../../IconButton.tsx"
import { Trash  } from 'react-bootstrap-icons';

interface CustomerGroup {
  g_id: number;
  g_name: string;
  g_description: string;
}

interface CustomerGroupDeletionModalProps {
  callback: (isSuccess: boolean) => void;
  hubGroups: CustomerGroup[];
}

const CustomerGroupDeletionModal: React.FC<CustomerGroupDeletionModalProps> = ({ callback, hubGroups }) => {
  const [show, setShow] = useState(false);
  const [groups, setGroups] = useState<CustomerGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<CustomerGroup | null>(null);
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleClose = (isSuccess: boolean) => {
    // reset everything
    setShowResponseDiv(false);
    setShow(false);
    setSelectedGroup(null);
    callback(isSuccess);
  };

  const handleShow = () => {
    setShow(true);
    setGroups(hubGroups);
  };

  
  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
  };

  const handleDelete = async () => {
    if (!selectedGroup) {
      displayError("Please select a group to delete.");
      return;
    }

    try {
      const url = `/api/groups/${selectedGroup.g_id}`;
      const result = await HttpClientUtil.deleteRequest(url);

      if (!result.success) {
        displayError(result.message);
        return;
      }

      const updatedGroups = groups.filter(group => group.g_id !== selectedGroup.g_id);
      console.log("Updated groups:", updatedGroups);
      
      hubGroups.splice(hubGroups.indexOf(selectedGroup), 1);
      handleClose(true);
      return;
    } catch (error) {
      console.error("Error deleting customer group:", error);
      displayError("Failed to delete customer group.");
    }
  };

  return (
    <>
      <IconButton
                btnStyle="btn btn-danger mx-1"
                text="Delete Group"
                onClick={handleShow}
                icon={<Trash  />}
                show={true}
                />

      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Customer Group</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>

              <div className="modal-body">
                <ErrorDiv
                  success={false}
                  errorMessage={responseMessage}
                  isVisible={showErrorDiv}
                  onClose={() => setShowResponseDiv(false)}
                />

                <ListGroup className="mb-3">
                  {groups.map((group) => (
                    <ListGroup.Item
                      action
                      variant="light"
                      key={group.g_id}
                      active={selectedGroup ? selectedGroup.g_id === group.g_id : false}
                      onClick={() => setSelectedGroup(group)}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <strong>{group.g_name}</strong>
                        <br />
                        <small>{group.g_description}</small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => handleClose(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-danger" onClick={handleDelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerGroupDeletionModal;
