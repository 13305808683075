import React from "react";
import { IArchivedLogModalViewModel } from "../js/viewmodels/ArchivedLogModalViewModel";
import { IAlertViewModel } from "../common/js/viewmodels/AlertViewModel";
// import ArchivedLog from "../js/classes/ArchivedLog";
import { Ban, Pencil } from 'react-bootstrap-icons';
import IconButton from "./IconButton.tsx";

import { IArchivedLogEditModalViewModel } from "../js/viewmodels/ArchivedEditLogModelViewModel";
export default function ArchivedLogModal(args: IArchivedLogModal) {
    const { deleteModel, model, editModel } = args;

    

    if (model.log === null) {
        return <></>;
    }
    

    return (
        <div className="modal show">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {model.log.cust} - {model.log.displayDate()}
                        </h5>
                    </div>
                    <div className="modal-body">
                        <ArchivedLogModalRow label="Operator" value={model.log.user} />
                        {/* would like to check if the customer is PRB but we aren't passing it  */}
                        {model.log.prb && (
                            <ArchivedLogModalRow label="Prb" value={model.log.prb ?? ''} />
                        )}
                        <ArchivedLogModalRow label="Caller" value={model.log.caller} />
                        <ArchivedLogModalRow label="Time Taken" value={model.log.displayTime()} />
                        <ArchivedLogModalRow label="Problem" value={model.log.problem} />
                        <ArchivedLogModalRow label="Solution" value={model.log.solution} last={true} />
                    </div>
                    <Footer deleteModel={deleteModel} model={model} editModel={editModel} />
                </div>
            </div>
        </div>
    );
}

function ArchivedLogModalRow(args: IArchivedLogModalRow) {
    const { label, value, last } = args;
    const divider = last ? <></> : <Divider />;

    return (
        <>
            <div className="row">
                <div className="col-4">{label}</div>
                <div className="col-8">{value}</div>
            </div>
            {divider}
        </>
    );
}

function Divider() {
    const styles = {
        height: '1px',
        width: '100%',
        background: '#0003',
        marginTop: '8px',
        marginBottom: '8px',
    };

    return <div style={styles}></div>;
}

function Footer({ deleteModel, model, editModel }: IArchivedLogModal) {
    let log = model.log ? model.log : null;

    const edit = () => {
        editModel.show(log); 
    };

    const promptDelete = () => {
        if(!log){
            return;
        }
        editModel.setLog(log);
        deleteModel.show({
            title: "Delete Log",
            msg: "Are you sure you want to delete this log entry?"
        });

    };

    let show = false;

    if (model.log && !model.log.isDeleted() && !model.log.transhistory){
        show = true;
    }


        return (
<div className="modal-footer d-flex justify-content-between">
    <div className="d-flex">
       
            <IconButton
            btnStyle="danger"
            text="Delete"
            onClick={promptDelete}
            icon={<Ban />}
            show={show}
            inProgress={false}
            inProgressText="Saving..."
        />
    </div>
    <div className="d-flex">
                <IconButton
                btnStyle="warning"
                text="Edit"
                onClick={edit}
                icon={<Pencil/>}
                show={show}
                />
        <button className="btn btn-secondary m-1" onClick={model.hide}>
            Close
        </button>
    </div>
</div>
    );
}


interface IArchivedLogModal {
    model: IArchivedLogModalViewModel;
    editModel: IArchivedLogEditModalViewModel;
    deleteModel: IAlertViewModel;
}


interface IArchivedLogModalRow {
    label: string;
    value: string;
    last?: boolean;
}