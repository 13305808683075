import { Dispatch, SetStateAction, useState } from "react";
import {Duration } from "luxon";
import ArchivedLog from "../classes/ArchivedLog.ts";
import { DateTime } from "luxon";
import Customer from "../classes/Customer.ts";
import User from "../classes/User.ts";
import { HttpClientUtil } from "../../components/common/HttpClientUtil.ts";
import { IArchivedLogModalViewModel } from "./ArchivedLogModalViewModel.ts";
import { IHistoryScreenViewModel } from "./HistoryScreenViewModel.ts";
import {parseFlexibleTime}  from "../util/timeParse.ts";
import { useLocalStorage } from 'usehooks-ts';
import { IUserSettings } from "./UserSettingsViewModel.ts";

export default function ArchivedLogEditModal(): IArchivedLogEditModalViewModel {
    const [log, setLog] = useState<ArchivedLog | null>(null);
    const [showModel, setShowModel] = useState(false);
    const [prb, setPrb] = useState<string | null>(null);
    const [solution, setSolution] = useState<string>("");
    const [problem, setProblem] = useState<string>("");
    const [caller, setCaller] = useState<string>("");
    const [customer, setCustomer] = useState<Customer | null>(null);
    const [users, setUsers] = useState<Array<User>>([]);
    const [user, setUser] = useState<User | null>(null);

    const [customers, setCustomers] = useState<Array<Customer>>([]);
    const [startDateTime, setStartDateTime] = useState<string>("");
    const [endDateTime, setEndDateTime] = useState<string>("");
    const [timeTaken, setTimeTaken] = useState<string>("");
    const [userSettings] = useLocalStorage <Partial< IUserSettings>>("userSettings", {
        defaultLogEditFormat: "hh:mm",
    });

    // Extract the defaultTimeFormat
    const defaultTimeFormat = userSettings.defaultLogEditFormat ?? "hh:mm";
    

    

    const calculateTimeTaken = (start: string, end: string) => {
        const startDt = DateTime.fromISO(start, {setZone: true});
        const endDt = DateTime.fromISO(end , {setZone: true});

        
        if (startDt.isValid && endDt.isValid) {
            // in case of invalid time, return 0 hours and 0 minutes
            const diff = endDt.diff(startDt, ['hours', 'minutes']) || Duration.fromObject({hours: 0, minutes: 0});
            return diff.toFormat(defaultTimeFormat);  
        } 
        return Duration.fromObject({hours: 0, minutes: 0}).toFormat(defaultTimeFormat);
    };

    // Show the archived log and populate the fields
    const show = (editLog: ArchivedLog | null) => {
        setShowModel(true);
        setLog(editLog);
    };

    // Set the log data and initialize the form fields
    const setLogData = (customer: Customer, editLog: ArchivedLog) => {
        setPrb(editLog.prb);
        setSolution(editLog.solution);
        setProblem(editLog.problem);
        setCaller(editLog.caller);
        setCustomer(customer);
        let user = users.find((u) => u.u_name === editLog.user) ?? null;

        setUser(user);
        const startDateTime = editLog.start.toFormat("yyyy-MM-dd'T'HH:mm"); 
        const endDateTime = editLog.end.toFormat("yyyy-MM-dd'T'HH:mm"); 

        setStartDateTime(startDateTime);
        setEndDateTime(endDateTime);
        setTimeTaken(calculateTimeTaken(startDateTime, endDateTime));
    };

    const handleStartDateTimeChange = (newStartDateTime: string) => {
        setStartDateTime(newStartDateTime);
        setTimeTaken(calculateTimeTaken(newStartDateTime, endDateTime));
    };
    
    const handleEndDateTimeChange = (newEndDateTime: string) => {
        setEndDateTime(newEndDateTime);
        setTimeTaken(calculateTimeTaken(startDateTime, newEndDateTime));
    };
    
    const handleTimeTakenChange = (newTimeTaken: string) => {
        setTimeTaken(newTimeTaken);
    
        const startDt = DateTime.fromISO(startDateTime, {setZone: true});
        const parsedDuration = parseFlexibleTime(newTimeTaken);
    
        if (parsedDuration) {
            // Only include hours and minutes, ignoring seconds
            const { hours, minutes } = parsedDuration.toObject();
            const newEndDateTime = startDt.plus({ hours: hours || 0, minutes: minutes || 0 }).toFormat("yyyy-MM-dd'T'HH:mm");
            setEndDateTime(newEndDateTime);
        } else {
            console.error("Invalid time input, unable to parse.");
        }
    };
    

    const cancel = () => {
        setShowModel(false);
        setLog(null);
    };

    const deletelog = (historymodel: IHistoryScreenViewModel, error: (msg: string) => void) => {
        if (!log) {
            error("Log is required");
            cancel();
            return;
        }

        log.problem = "(DELETED) " + log.problem;
        log.deleted = true;
        const sendableLog = log.toSendable();
        try {
            const request = { log: sendableLog };
            const url = `/api/editlog/${sendableLog.T_ID}`;
            
            HttpClientUtil.deleteRequest(url, request, true).then((result) => {
                if (result.success) {
                    if(historymodel.logs){
                        let logs = historymodel.logs;
                        const index = logs.findIndex(l => l.id === log.id);

                        if (index !== -1) {
                            logs = [
                                ...logs.slice(0, index),  
                                log,               
                                ...logs.slice(index + 1)   
                            ];
                            historymodel.setLogs(logs);
                        }                    
                    }
                    cancel();
                } else {
                    error("Failed to update log");
                }
            });
        } catch (err) {
            console.error("Error submitting log:", err);
            error("Error submitting log");
        }
    };

    const submitLog = (historymodel: IHistoryScreenViewModel, model: IArchivedLogModalViewModel, error: (msg: string) => void) => {
        if (!log) {
            error("Log is required");
            cancel();
            return;
        }
        
    
        if (!user) {
            error("User is required");
            cancel();
            return;
        }
    
        if (!customer) {
            error("Customer is required");
            cancel();
            return;
        }
    
        if (!startDateTime) {
            error("Start date is required");
            cancel();
            return;
        }
    
        if (!endDateTime) {
            error("End date is required");
            cancel();
            return;
        }
    
        // Validate the format and ensure endDateTime is after startDateTime
        const startDt = DateTime.fromISO(startDateTime, { setZone: true });
        const endDt = DateTime.fromISO(endDateTime, { setZone: true });
    
        if (!startDt.isValid) {
            error("Invalid start date format");
            cancel();
            return;
        }
    
        if (!endDt.isValid) {
            error("Invalid end date format");
            cancel();
            return;
        }

        /* todo make this only available for brett */
        if (endDt < startDt) {
            error("End date must be after start date");
            cancel();
            return;
        }
    
        // Calculate the time taken between start and end dates
        const diff = endDt.diff(startDt, ['hours', 'minutes']);
        const totalHours = diff.hours;
        const totalMinutes = diff.minutes;
        const timeTakenFormatted = `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}`;
    
        const justminutes = (totalHours * 60) + totalMinutes;

        setTimeTaken(timeTakenFormatted);
    
        // Convert to string...
        let custDial = customer.code;
        let optr  = user.u_name;
        
        const updatedLog = new ArchivedLog({
            ...log,
            T_ID: log.id,
            T_START: startDt,
            T_FINISH: endDt,
            T_DIALCODE: custDial,
            T_PRB: prb,
            T_CALLER: caller,
            T_PROBLEM: problem,
            T_SOLUTION: solution,
            T_OPTR: optr,
            T_MINUTES: justminutes,
            T_HISTORY: log.transhistory
        });

        const sendableLog = updatedLog.toSendable();
        // console.log("Updated log:", sendableLog);
        
        try {
            const request = { log: sendableLog };
            const url = `/api/editlog/${sendableLog.T_ID}`;
            
            HttpClientUtil.putRequest(url, request, true).then((result) => {
                if (result.success) {
                    model.setLog(updatedLog);
                    if(historymodel.logs){
                        let logs = historymodel.logs;
                        const index = logs.findIndex(l => l.id === updatedLog.id);

                        if (index !== -1) {
                            logs = [
                                ...logs.slice(0, index),  
                                updatedLog,               
                                ...logs.slice(index + 1)   
                            ];
                            historymodel.setLogs(logs);
                        }                    
                    }
                    cancel();
                } else {
                    error("Failed to update log");
                }
            });
        } catch (err) {
            console.error("Error submitting log:", err);
            error("Error submitting log");
        }
    };

    return {
        log,
        show,
        setLog,
        showModel,
        setLogData,
        cancel,
        submitLog,
        prb, setPrb,
        solution, setSolution,
        problem, setProblem,
        customer, setCustomer,
        users,
        user,
        setUser, setUsers,
        caller, setCaller,
        startDateTime, setStartDateTime: handleStartDateTimeChange,
        endDateTime, setEndDateTime: handleEndDateTimeChange,
        timeTaken, setTimeTaken: handleTimeTakenChange,
        deletelog,

        customers, setCustomers
    };
}

export interface IArchivedLogEditModalViewModel {
    log: ArchivedLog | null;
    showModel: boolean;
    show: (log: ArchivedLog | null ) => void;
    setLog(log: ArchivedLog): void;
    setLogData: (Customer: Customer, editLog: ArchivedLog) => void;
    submitLog: (historymodel: IHistoryScreenViewModel, model: IArchivedLogModalViewModel, error: (msg: string) => void) => void;
    cancel: () => void;
    deletelog: ( historymodel: IHistoryScreenViewModel, error: (msg: string) => void) => void;
    solution: string;
    setSolution: (value: string) => void;
    prb: string | null;
    setPrb: (value: string) => void;
    problem: string;
    setProblem: (value: string) => void;
    customer: Customer | null;
    setUser: (user: User) => void;
    setUsers: Dispatch<SetStateAction<Array<User>>>;
    customers: Array<Customer>;
    users: Array<User>;
    user: User | null;
    setCustomer: Dispatch<SetStateAction<Customer | null>>;
    caller: string;
    setCaller: (value: string) => void;
    startDateTime: string;
    endDateTime: string;
    setEndDateTime: (value: string) => void;
    setStartDateTime: (value: string) => void;
    timeTaken: string;
    setTimeTaken: (value: string) => void;
    setCustomers: Dispatch<SetStateAction<Array<Customer>>>;
}
