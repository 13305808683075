/**
 * Saves the customer details by making an API call to the server.
 *
 * @param {Object} customerDetails - The customer details object containing the customer information.
 * @param {Array} fields - An array of field objects containing the field information.
 * @param {Object} formData - The form data object containing the form input values.
 * @param {Object} HttpClientUtil - The HTTP client utility object for making API calls.
 * @param {Function} setResponseMessage - A function to set the response message.
 * @param {Function} setIsSuccess - A function to set the success flag.
 * @param {Function} setShowResponseDiv - A function to set the visibility of the response div.
 * @return {Promise<void>} A promise that resolves when the customer details are saved successfully.
 */
export const defaultHandleSave = async (
  customerDetails,
  fields,
  formData,
  HttpClientUtil,
  setResponseMessage,
  setIsSuccess,
  setShowResponseDiv
) => {
  if (customerDetails.C_DIALCODE) {
    // Create a map from field IDs to field keys
    const idToKeyMap = fields.reduce((acc, field) => {
      acc[field.id] = field.key;
      return acc;
    }, {});

    // Create a map from field keys to field labels
    const keyToLabelMap = fields.reduce((acc, field) => {
      acc[field.key] = field.label;
      return acc;
    }, {});
    const formDataMapped = Object.keys(formData).reduce((acc, id) => {
      let value = formData[id];
      if (Array.isArray(value)) {
        acc[idToKeyMap[id]] = value.length > 0 ? value[0] : '';
      } else if (typeof value === 'object' && value !== null) {
        const keys = Object.keys(value);
        acc[idToKeyMap[id]] = keys.length > 0 ? value[keys[0]] : '';
      } else {
        // Convert booleans to 'Y' or 'N'
        if (typeof value === 'boolean') {
          value = value ? 'Y' : 'N';
        }
        acc[idToKeyMap[id]] = value;
      }
      return acc;
    }, {});

    try {
      const url = `/api/customer${customerDetails.C_DIALCODE ? `/${customerDetails.C_DIALCODE}` : ''}`;
      let response;

      // Determine the HTTP method and call the corresponding HttpClientUtil function
      if (customerDetails.method === 'DELETE') {
        response = await HttpClientUtil.deleteRequest(url, formDataMapped);
      } else if (customerDetails.method === 'PUT') {
        response = await HttpClientUtil.putRequest(url, formDataMapped);
      } else {
        response = await HttpClientUtil.postRequest(url, formDataMapped);
      }

      if (!response.success && response.message) {
        // Replace identifiers with labels in the error message
        const readableMessage = response.message.replace(
          /C_[A-Z0-9_]+/g,
          (match) => keyToLabelMap[match] || match
        );
        setResponseMessage(readableMessage);
      } else {
        setResponseMessage(response.message);
      }

      setIsSuccess(response.success);
      setShowResponseDiv(true);
    } catch (error) {
      console.error('Failed to save the customer:', error);
      setResponseMessage(error.toString());
      setIsSuccess(false);
      setShowResponseDiv(true);
    }
  } else {
    alert('Customer Name and Invoice Name must be filled out.');
  }
};
