import React, { useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";

interface Setting {
  c_type: string;
  c_description: string;
}

interface SettingsModalProps {
  custCode: string;
  callback: (data: object, pushComment: boolean) => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ custCode, callback }) => {
  const [show, setShow] = useState(false);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [selectedType, setSelectedType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const fetchSettings = async () => {
    const url = `/api/settings`;
    const result = await HttpClientUtil.getRequest(url);

    if (result.success) {
      setSettings(result.data);
    } else {
      displayError(result.message);
    }
  };

  const handleClose = (dataResponse: object = {}, deleteEvent: boolean = false) => {
    setShowResponseDiv(false);
    setShow(false);
    setSelectedType("");
    setDescription("");
    setValue("");

    const pushComment = deleteEvent ? deleteEvent : false;
    callback(dataResponse, pushComment);
  };

  const handleShow = () => {
    setShow(true);
    fetchSettings();
  };

  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
    setIsSuccess(!message);
  };

  const handleDelete = async () => {
    if (!selectedType) {
      displayError("Please select a type to delete.");
      return;
    }

    try {
      const url = `/api/settings`;
      const payload = { c_type: selectedType };

      const result = await HttpClientUtil.deleteRequest(url, payload);
      if (!result.success) {
        displayError(result.message);
      } else {
        handleClose(result, true);
      }
    } catch (error) {
      console.error("Error submitting setting:", error);
      displayError("Failed to submit setting.");
    }
  };

  const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value;
    setSelectedType(type);
    const selectedSetting = settings.find((setting) => setting.c_type === type);
    setDescription(selectedSetting ? selectedSetting.c_description : "");
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!selectedType || !value) {
      displayError("Please select a type and enter a value.");
      return;
    }

    try {
      const url = `/api/settings`;
      const payload = { c_type: selectedType, value, c_dialCode: custCode };
      const result = await HttpClientUtil.postRequest(url, payload);
      if (!result.success) {
        displayError(result.message);
      } else {
        handleClose(result);
      }
    } catch (error) {
      console.error("Error submitting setting:", error);
      displayError("Failed to submit setting.");
    }
  };

  return (
    <>
      <button
        className="btn btn-secondary me-2"
        type="button"
        style={{ float: "left" }}
        onClick={handleShow}
      >
        Add Pre-defined Setting
      </button>

      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create or Update Setting</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose()}></button>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  {showErrorDiv && (
                    <Alert
                      variant={isSuccess ? "success" : "danger"}
                      onClose={() => setShowResponseDiv(false)}
                      dismissible
                    >
                      {responseMessage}
                    </Alert>
                  )}
                  <Form.Group controlId="settingType">
                    <Form.Label>Type:</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedType}
                      onChange={handleTypeChange}
                    >
                      <option value="">Select a type</option>
                      {settings.map((setting) => (
                        <option key={setting.c_type} value={setting.c_type}>
                          {setting.c_type}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="settingDescription" className="mt-3">
                    <Form.Label>Description:</Form.Label>
                    <Form.Control type="text" value={description} readOnly />
                  </Form.Group>
                  <Form.Group controlId="settingValue" className="mt-3">
                    <Form.Label>Value:</Form.Label>
                    <Form.Control
                      type="text"
                      value={value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
                      placeholder="Enter the value"
                    />
                  </Form.Group>
                </div>

                <div className="modal-footer">
                  <div className="d-flex justify-content-between w-100">
                    <Button className="mx-2" variant="danger" onClick={handleDelete}>
                      Delete Template
                    </Button>
                    <div>
                      <Button className="mx-1" variant="secondary" onClick={() => handleClose()}>
                        Close
                      </Button>
                      <Button className="mx-1" type="submit" variant="primary">
                        Save Set
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsModal;
