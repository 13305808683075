import { useState } from "react";
import Customer from "../classes/Customer";
import { HttpClientUtil } from "../../components/common/HttpClientUtil.ts";

export default function AdminScreenViewModel(): IAdminScreenViewModel {
    const [customers, setCustomers] = useState<Array<Customer>>([]);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showErrorDiv, setShowResponseDiv] = useState<boolean>(false);
    const [responseMessage, setResponseMessage] = useState<string>("");
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(false);
    const [showEndOfMonthModal, setShowEndOfMonthModal] = useState<boolean>(false);

    const handleEndOfMonthPrompt = (): void => {
        setShowConfirmModal(true);
    };

    const endOfMonth = async (data: string | null): Promise<void> => {
        setIsSuccess(false);
        if (!data) {
            setShowResponseDiv(true);
            setResponseMessage("Please enter a valid date");
            return;
        }

        const date = new Date(data);
        if (isNaN(date.getTime())) {
            setShowResponseDiv(true);
            setResponseMessage("Please enter a valid date");
            return;
        }

        setShowEndOfMonthModal(false);
        setShowConfirmModal(false);
        setShowLoadingScreen(true);

        const url = `/run/end-of-month`;
        try {
            const request = { date };
            const response = await HttpClientUtil.postRequest(url, request);

            setShowResponseDiv(true);
            setIsSuccess(response.success);
            setResponseMessage(response.message);
        } catch (error) {
            console.error("Failed to run end of month process:", error);
            setShowResponseDiv(true);
            setResponseMessage("An error occurred while processing your request.");
        } finally {
            setShowLoadingScreen(false);
        }
    };

    const handleConfirm = (): void => {
        setShowConfirmModal(false);
        setShowEndOfMonthModal(true);
    };

    return {
        customers,
        setCustomers,
        showConfirmModal,
        setShowConfirmModal,
        showErrorDiv,
        setShowResponseDiv,
        responseMessage,
        setResponseMessage,
        isSuccess,
        setIsSuccess,
        showLoadingScreen,
        setShowLoadingScreen,
        showEndOfMonthModal,
        setShowEndOfMonthModal,
        handleEndOfMonthPrompt,
        endOfMonth,
        handleConfirm,
    };
}

export interface IAdminScreenViewModel {
    customers: Array<Customer>;
    setCustomers: (customers: Array<Customer>) => void;
    showConfirmModal: boolean;
    setShowConfirmModal: (show: boolean) => void;
    showErrorDiv: boolean;
    setShowResponseDiv: (show: boolean) => void;
    responseMessage: string;
    setResponseMessage: (message: string) => void;
    isSuccess: boolean;
    setIsSuccess: (success: boolean) => void;
    showLoadingScreen: boolean;
    setShowLoadingScreen: (show: boolean) => void;
    showEndOfMonthModal: boolean;
    setShowEndOfMonthModal: (show: boolean) => void;
    handleEndOfMonthPrompt: () => void;
    endOfMonth: (data: string | null) => Promise<void>;
    handleConfirm: () => void;
}