import React, { useEffect } from "react";
import Checkbox from "../common/components/Checkbox.tsx";
import DeleteLogModal from "./DeleteArchiveLogModal.tsx";
import Autocomplete from "./Autocomplete.tsx";
import UserAutocomplete from "./UserAutocomplete.tsx";
import InputField from "../common/components/InputField.tsx";
import SubmitButton from "../common/components/SubmitButton.tsx";
import AlertModal from "../common/components/AlertModal.tsx";
import AlertViewModel from "../common/js/viewmodels/AlertViewModel.ts";
import { IHistoryScreenViewModel } from "../js/viewmodels/HistoryScreenViewModel";
import ArchivedLog from "../js/classes/ArchivedLog";
import ArchivedLogModal from "./ArchivedLogModal.tsx";
import ArchivedLogModalViewModel, { IArchivedLogModalViewModel } from "../js/viewmodels/ArchivedLogModalViewModel.ts";
import ArchivedLogEditModal from "./ArchivedLogEditModal.tsx";
import ArchivedLogEditModalViewModel, { IArchivedLogEditModalViewModel } from "../js/viewmodels/ArchivedEditLogModelViewModel.ts";
import Customer from "../js/classes/Customer.ts";


export default function HistoryScreen(args: IHistoryScreen) {

	const { model } = args;
	const archivedLogModel = ArchivedLogModalViewModel();
    const editlogModel =  ArchivedLogEditModalViewModel();

	const deleteModel = AlertViewModel();

	const error = (msg: string) => alertModel.show({ title: 'Error', msg });

	useEffect(() => {
		editlogModel.setCustomers(model.customers);
		editlogModel.setUsers(model.users);

		// set date desc as default
		model.setSortOrder({ order: 'date', asc: false });
	},[]);
	const alertModel = AlertViewModel();

	const search = () => {
		model.search(error => {
			alertModel.show({ msg:error, title:'Error' });
		});
	};

	const changeSortOrder = (newOrder: string) => {
		var {order, asc} = model.sortOrder;
		asc = (order === newOrder) ? !asc : true;
		model.setSortOrder({order: newOrder, asc});
	};

	const sortByDate = () => changeSortOrder('date');
	const sortByCust = () => changeSortOrder('cust');
	const sortByUser = () => changeSortOrder('user');
	const sortByCaller = () => changeSortOrder('caller');
	const sortByTime = () => changeSortOrder('time');
	const sortByProblem = () => changeSortOrder('problem');
	const sortBySolution = () => changeSortOrder('solution');

	const showLog = (log: ArchivedLog) => {	
		if (model.showOnlyMine && log.user !== model.user.u_name){
			return false;
		}else if (!model.showDeleted && log.isDeleted() && !model.showOnlyDeleted){
			return false;
		}else if (model.showOnlySusLogs){
			return Object.keys(log.getLogStyle()).length > 0;
		} else if (model.showOnlyDeleted){
			return log.isDeleted();
		}else {
			return true;
		}
	};
	const sortLog = (a: ArchivedLog, b: ArchivedLog) => {
		var {order, asc} = model.sortOrder;
		var diff = a.compareTo(b, order);
		return asc ? diff : -diff;
	};
	const logs = model.logs
	.filter(showLog)
	.sort(sortLog)
	.map((l, index) => {
		let customerCode = model.customers.find(c => c.code.toLowerCase() === l.cust.toLowerCase());
		if (!customerCode) 
			customerCode = Customer.createEmpty();
		
		const key = `${l.start.toString()}-${l.cust}-${index}`;

		return (
			<TableRow 
				key={key}  
				log={l} 
				model={archivedLogModel} 
				editmodel={editlogModel} 
				customerCode={customerCode} 
			/>
		);
	});
	return (
		<>
			{/* <h6>*This page now retrieves all logs</h6> */}
			<br/>
			<div className="row">
				<div className="col s4 m3 l2">
					<Autocomplete
						name="Customer"
						id="historyCustomer"
						datalist={model.customers}
						forceSelection={false}
						setValue={model.setCustomer}
						labelKey="name"
						clearOnComplete={false}
						/>
				</div>
				<div className="col s4 m3 l2">
				<UserAutocomplete
					name="User"
					id="User"
					labelKey="u_name"
					datalist={model.users} // Users list set from logs
					forceSelection={false}
					setValue={model.setUserSearch}
					clearOnComplete={false}
				/>
				</div>
				</div>
				<div className="row">
				<div className="col s4 m3 l2">
					<InputField
						id="fromDate"
						value={model.fromDate}
						setValue={model.setFromDate}
						name="From:"
						type="date"
						/>
				</div>
				<div className="col s4 m3 l2">
					<InputField
						id="toDate"
						value={model.toDate}
						setValue={model.setToDate}
						name="To:"
						type="date"
						/>
				</div>
				<div className="col s4 m3 l2">
					<SubmitButton
						text="Search"
						onClick={search}
						requestInProgress={model.requestInProgress}
						loadingText="Searching..."
						/>
				</div>
			</div>

			<div className="row">
				<div className="col col-auto">
					Filter:
				</div>
				<div className="col col-auto">
					<Checkbox
						id="showOnlyMine"
						label="Only My Logs"
						value={model.showOnlyMine}
						setValue={model.setShowOnlyMine}
						/>
				</div>
				<div className="col col-auto">
					<Checkbox
						id="showDeleted"
						label="Show Deleted Logs"
						value={model.showDeleted}
						setValue={model.setShowDeleted}
						/>
				</div>
				<div className="col col-auto">
					<Checkbox
						id="showOnlySusLogs"
						label="Show Only Weird Logs"
						value={model.showOnlySusLogs}
						setValue={model.setShowOnlySusLogs}
						/>
				</div>
				<div className="col col-auto">
					<Checkbox
						id="showOnlyDeleted"
						label="Show Only Deleted Logs"
						value={model.showOnlyDeleted}
						setValue={model.setShowOnlyDeleted}
						/>
				</div>
			</div>

			<br/>

			<table className="table table-hover">
				<thead>
					<tr>
						<th className="clickable" onClick={sortByDate}>Date</th>
						<th className="clickable" onClick={sortByCust}>Customer</th>
						<th className="clickable" onClick={sortByUser}>Operator</th>
						<th className="clickable" onClick={sortByCaller}>Caller</th>
						<th className="clickable" onClick={sortByTime}>Time</th>
						<th className="clickable hide-on-med-and-down" onClick={sortByProblem}>Problem</th>
						<th className="clickable hide-on-med-and-down" onClick={sortBySolution}>Solution</th>
					</tr>
				</thead>
				<tbody>
					{logs}
				</tbody>
			</table>
			<ArchivedLogModal deleteModel={deleteModel} model={archivedLogModel} editModel={editlogModel}/>
			<ArchivedLogEditModal historymodel={model} model={archivedLogModel} editmodel={editlogModel} alertModel={alertModel}/>
			<DeleteLogModal historymodel={model} model={editlogModel} alertModel={deleteModel} error={error}/>
			<AlertModal model={alertModel}/>
        </>
	)
}

function TableRow(args: ITableRow) {
	const { log, model, editmodel, customerCode } = args;
	const click = () => {
		
		model.setLog(log);
		
		editmodel.setLogData(customerCode, log);


	}

	// const style = log.isDeleted() ? { textDecoration: 'line-through', backgroundColor: '#FF6347' }: {};
	const style = log.getLogStyle();
	// wrap date as chrome browsers have issues. 
	const dateStyle: React.CSSProperties = {
		...style, 
		whiteSpace: 'nowrap',
	};
	return (

	  <tr onClick={click}>
		<td style={dateStyle}>{log.displayDate()}</td>
		<td style={style}>{log.getCustomerName()}</td>
		<td style={style}>{log.user}</td>
		<td style={style}>{log.caller}</td>
		<td style={style}>{log.displayTime()}</td>
		<td style={style} className="hide-on-med-and-down">{log.problem}</td>
		<td style={style} className="hide-on-med-and-down">{log.solution}</td>
	  </tr>
	);
  }
  

interface ITableRow{
	log: ArchivedLog;
	model: IArchivedLogModalViewModel;
	editmodel: IArchivedLogEditModalViewModel;
	customerCode: Customer;
}

interface IHistoryScreen{
	model: IHistoryScreenViewModel;
}
