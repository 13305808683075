import React, { useEffect } from 'react';

/* this is a component that will change the tab title depending on if the tab is active or not */
interface TabVisibilityHandlerProps {
  activeTitle: string;
  inactiveTitle: string;
  enabletitleChange?: boolean;
}

const TabVisibilityHandler: React.FC<TabVisibilityHandlerProps> = ({
  activeTitle,
  inactiveTitle,
  enabletitleChange = true,
}) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && enabletitleChange) {
        document.title = inactiveTitle;
      } else {
        document.title = activeTitle;
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    document.title = document.hidden && enabletitleChange ? inactiveTitle : activeTitle;

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [activeTitle, inactiveTitle, enabletitleChange]);

  return null;
};

export default TabVisibilityHandler;
