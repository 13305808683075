import React, { useState } from "react";

import ErrorDiv from "../../common/ErrorModal";
import { HttpClientUtil } from "../../common/HttpClientUtil.ts";
import  CustomerGroup  from "../../../js/classes/CustomerGroup.ts";
import IconButton from "../../IconButton.tsx"
import { PeopleFill } from 'react-bootstrap-icons';

interface CustomerGroupCreationModalProps {
  callback: (isSuccess: boolean) => void;
  hubGroups: CustomerGroup[]
}

const CustomerGroupCreationModal: React.FC<CustomerGroupCreationModalProps> = ({ callback, hubGroups }) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showErrorDiv, setShowResponseDiv] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleClose = (isSuccess: boolean) => {
    // Reset everything
    setShowResponseDiv(false);
    setShow(false);
    callback(isSuccess);
  };

  const handleShow = () => setShow(true);

  const displayError = (message: string) => {
    setShowResponseDiv(true);
    setResponseMessage(message || "");
  };

  const handleSave = async () => {
    if (!name) {
      displayError("Please enter group name");
      return;
    }

    if (!description) {
      displayError("Please enter group description");
      return;
    }

    try {
      const request = { name, description };
      const url = `/api/groups`;
      const result = await HttpClientUtil.postRequest(url, request);

      if (!result.success) {
        displayError(result.message);
        return;
      }

      hubGroups.push(result.data as CustomerGroup);
      handleClose(true);
    } catch (error) {
      console.error("Error creating customer group:", error);
    }
  };

  return (
    <>
      {/* <button className="btn btn-secondary mx-1" type="button" onClick={handleShow}>
        Create New Customer Group
      </button> */}
              {/* <div className="modal-header"> */}
              <IconButton
                btnStyle="btn btn-secondary mx-1"
                text="Create Group"
                onClick={handleShow}
                icon={<PeopleFill />}
                show={true}
                />
      {show && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Customer Group</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => handleClose(false)}></button>
              </div>

              <div className="modal-body">
                <ErrorDiv
                  success={false}
                  errorMessage={responseMessage}
                  isVisible={showErrorDiv}
                  onClose={() => setShowResponseDiv(false)}
                />
                <form>
                  <div className="mb-3">
                    <label htmlFor="formGroupName" className="form-label">
                      Group Name:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupName"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="formGroupDescription" className="form-label">
                      Group Description:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="formGroupDescription"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => handleClose(false)}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSave}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerGroupCreationModal;
