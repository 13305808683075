import { Duration } from "luxon";

function parseFlexibleTime(input: string) {
        /*
     cause people like this to be a thing
     welcome to the stupid flexable time interpreter
     type in what ever you want and it will work
        
     XDDDDDD
     */

    // Initialize time parts
    let hours = 0, minutes = 0, seconds = 0;

    // Regex to match time expressions with units (e.g., "2 hours", "55m")
    const timeRegex = /(\d+)\s*(hours?|h|minutes?|m|seconds?|s)?/gi;
    const colonSeparated = input.includes(":");
    const numericOnly = /^\d+$/.test(input.trim());

    if (colonSeparated) {
        const timeParts = input.split(":").map(Number);
        if (timeParts.length === 3) {
            [hours, minutes, seconds] = timeParts;
        } else if (timeParts.length === 2) {
            [hours, minutes] = timeParts;
        } else if (timeParts.length === 1) {
            minutes = timeParts[0];
        }
    } else if (numericOnly) {
        minutes = parseInt(input, 10);
    } else {
        // Handle natural language or mixed input
        let match;
        // nextUnit is h for 
        // 12 51, should be 12 hours 51 minutes
        let nextUnit = 'h';
        while ((match = timeRegex.exec(input)) !== null) {
            const value = parseInt(match[1], 10);
            const unit = match[2]?.toLowerCase() || nextUnit;
            // if I enter 1h 55 it should be 1 hour 55 minutes
            // if I enter 15m 55 it should be 15 minutes 55 seconds
            if (unit.startsWith('h')) {
                hours += value;
                nextUnit = 'm';
            } else if (unit.startsWith('m')) {
                minutes += value;
                nextUnit = 's';
            } else if (unit.startsWith('s')) {
                seconds += value;
                nextUnit = 's';
            } else {
                // Assume missing unit means minutes
                minutes += value;
                nextUnit = 'm';
            }
        }
    }

    // Normalize minutes and seconds
    if (seconds >= 60) {
        minutes += Math.floor(seconds / 60);
        seconds %= 60;
    }
    if (minutes >= 60) {
        hours += Math.floor(minutes / 60);
        minutes %= 60;
    }

    if (hours < 0 || minutes < 0 || seconds < 0) {
        console.error("Invalid time input: negative values are not allowed.");
        return null;
    }

    return Duration.fromObject({ hours, minutes, seconds });
}
export { parseFlexibleTime };