import React from "react";
import { IRecentLogViewModel } from "../../js/viewmodels/RecentLogViewModel"; // Import the view model

export default function RecentCustomerLogsModal(args: IRecentLogsModal) {
  const { model } = args;

  if (!model.customer) {
    return <></>;
  }

  const close = () => {
    model.close();
  };



  return (
    <div
      className="modal fade show"
      id="oldCustLogs"
      tabIndex={-1}
      role="dialog"
      style={{ display: "block", zIndex: 1050 }}
      aria-labelledby="recentLogsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="recentLogsModalLabel">
              Recent Logs for Customer {model.customer.name}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={close}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body" style={{ maxHeight: "400px", overflowY: "auto" }}>
            {model.loading && <div className="text-center">Loading...</div>}
            {!model.loading && model.logs.length === 0 && (
              <p className="text-center">No logs found for this customer.</p>
            )}
            {!model.loading &&
              model.logs.map((log, index) => (
                <div className="card mb-3" key={index} style={log.getLogStyle()}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <strong>Date:</strong> {log.displayDate()}
                      </div>
                      <div className="col-md-4">
                        <strong>Caller:</strong> {log.caller || "(No Caller)"}
                      </div>
                      <div className="col-md-4">
                        <strong>Time Taken:</strong> {log.displayTime()}
                      </div>
                    </div>
                    <div className="mt-3">
                      <strong>Problem:</strong> {log.problem}
                    </div>
                    <div className="mt-2">
                      <strong>Solution:</strong> {log.solution}
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={close}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IRecentLogsModal {
  model: IRecentLogViewModel;
}
