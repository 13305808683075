import React, { ReactElement } from "react";
import LoadingButton from "../common/components/LoadingButton.tsx";

export default function IconButton(args: IIconButton){

    const { show, btnStyle, icon, text, onClick, inProgress = false, inProgressText = "Loading..." } = args;

    if (!show){
        return (<></>);
    }

    if (inProgress){
        return (
            <LoadingButton text={inProgressText}/>
        );
    }

    const classes = "m-1 btn btn-"+btnStyle;

    return (
        <button onClick={onClick} className={classes}>
            {icon}
            &nbsp;{text}
        </button>
    );

}

export interface IIconButton{
    btnStyle: string;
    text: string;
    onClick: () => void;
    icon: ReactElement;
    show: boolean;
    inProgress?: boolean;
    inProgressText?: string;
}